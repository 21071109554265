import React, { Component } from 'react';

class ModalProduto extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.modalRef && !this.modalRef.current.contains(event.target)) {
      this.props.onClose();
    }
  };

  render() {
    const { isOpen, children } = this.props;

    return (
      <div className={`modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-content" ref={this.modalRef}>
            aaaaaaaaaaaaaaa
          {children}
        </div>
      </div>
    );
  }
}

export default ModalProduto;
