import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCoffee,
    faUser,
    faMagnifyingGlass,
    faBars,
    faShoppingCart,
    faHeart,
    
 } from '@fortawesome/free-solid-svg-icons'

 import MyContext from "../../../../../comps/context";
class IputPsq extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    load:false,
    texto:''
    }
  

  }




 componentDidMount(){
    var txt  = window.location.pathname.split('/busca/')[1];
    if(txt != undefined){
        this.setState({texto:decodeURIComponent(txt)})
    }
   
 }


 handleChange = (event) => {
    this.setState({ texto: event.target.value }); // Atualiza o estado com o valor do input
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
        if (!this.state.texto || this.state.texto.trim().length === 0) {
            return false;
         }
         this.props.navigateTo('/busca/'+this.state.texto)
    }
  }

  render() {

return(<> <div class="navbar-search search-style-5">
<div class="search-select"></div>
<div class="search-input">

<input type="text" onKeyUp={(e)=>{


}}  onClick={()=>{

}}  value={this.state.texto}
onChange={this.handleChange} placeholder="Pesquise por marcas,produos e muito mais..."   onKeyDown={this.handleKeyDown} />


</div>
<div class="search-btn">
<button onClick={()=>{
if (!this.state.texto || this.state.texto.trim().length === 0) {
    return false;
 }
 this.props.navigateTo('/busca/'+this.state.texto)
}}>  <FontAwesomeIcon icon={faMagnifyingGlass} /></button>
</div>





</div></>)

    
    
  }
}
IputPsq.contextType = MyContext;
export default IputPsq;