

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';

class Cadastro extends Component {
constructor(props) {
super(props);
this.state = {
email: '',
password: '',
};
}

componentDidMount(){
  console.log(this)
    document.title = "Cadastro - Welessa Eletronic"
  }

handleEmailChange = (event) => {
this.setState({ email: event.target.value });
}

handlePasswordChange = (event) => {
this.setState({ password: event.target.value });
}

handleLogin = () => {
console.log(this.state.email);
}

render() {
  return (
    <>
   
   <div class="account-login section">
        <div class="container">
        <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
        <div class="register-form">
        <div class="title">
        <h3>Não tem conta ? Cadastre-se agora</h3>
        <p>O registro leva menos de um minuto, Mas oferece controle total sobre os produtos.</p>
        </div>
        <form class="row" method="post">
        <div class="col-sm-6">
        <div class="form-group">
        <label for="reg-fn">Nome:</label>
        <input class="form-control" type="text" id="nomeUsr" required=""/>
        </div>
        </div>
        <div class="col-sm-6">
        <div class="form-group">
        <label for="reg-ln">Sobrenome:</label>
        <input class="form-control" type="text" id="sbnUsr" required=""/>
        </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-ln">CPF ou CNPJ:</label>
            <input class="form-control" type="text" id="ccUsr" required=""/>
            </div>
            </div>
        <div class="col-sm-6">
        <div class="form-group">
        <label for="reg-email">E-mail:</label>
        <input class="form-control" type="email" id="emailUsr" required=""/>
        </div>
        </div>
        <div class="col-sm-6">
        <div class="form-group">
        <label for="reg-phone">Telefone:</label>
        <input class="form-control" type="text" id="telUsr" required="" maxlength="15"/>
        </div>
        </div>
        <div class="col-sm-6">
        <div class="form-group">
        <label for="reg-pass">Sua senha:</label>
        <input class="form-control" type="password" id="senUsr" required=""/>
        </div>
        </div>
        <div class="col-sm-6">
        <div class="form-group">
        <label for="reg-pass-confirm">Confirme a senha:</label>
        <input class="form-control" type="password" id="csenUsr" required=""/>
        </div>
        </div>
        <div class="form-group">
        <div class="area-cpt">
        <img src="https://www.welessa.com/api/gerar-captcha/#1687565978683" alt="Atualize a pagina!" class="cptCad"/>
        <i class="fa fa-refresh" id="atzCpt" aria-hidden="true"></i>
        </div>
        <input class="form-control" placeholder="Captcha:" type="text" id="captcha" required="" autocomplete="off"/>
  </div>
        <div class="button">
        <button class="btn" id="envCad" type="submit">Enviar</button>
        </div>
        <p class="outer-link">Você já é cadastrado <Link to="/cliente/login/">Faça login agora</Link>
        </p>
        </form>
        </div>
        </div>
        </div>
        </div>
        </div>
    
    
    
    
    </>
    );
}
}
export default Cadastro;