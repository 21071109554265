import React,{useState} from "react";
import { Link } from "react-router-dom"
import { Button,Modal } from 'react-bootstrap'
export default function Baixar() {



return (
<>

<div class="error-area">
  <div class="d-table">
    <div class="ajusta">
      <div class="container">
        <div class="error-content">
          <img class="ErrImg" src="http://192.168.0.121/_next/static/imagens/undraw_download_re_li50.svg"/>
          <h2> Baixe o nosso aplicativo e tenha a nossa loja na palma da sua mão!</h2>
          <p></p>
          <div class="button">
            <a class="btn" href="/">Baixar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</>
);
}
