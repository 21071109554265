import React from "react";
import { Link } from "react-router-dom"
import HeroArea from "./elementos/HeroArea";
import FeaturedCategories from "./elementos/FeaturedCategories";
import TopPdts from "./elementos/TopPdts";
import Recentes from "./elementos/Recentes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../comps/Footer";
export default function Inicio(props) {
    document.title = "WeLessa - Página inicial"
return (
<div>
<HeroArea host={props.host}/>
<FeaturedCategories host={props.host}/>
<TopPdts host={props.host}/>
<Recentes host={props.host}/>
<ToastContainer />
<Footer/>
</div>
);
}
