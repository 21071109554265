import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faUser,
    faTruck,
    faCreditCard,
    faPlus,
    faPencil,
    faTrash
    } from '@fortawesome/free-solid-svg-icons'
import ButtonRipple from '../../Produto/ButtonRipple';
import ReactInputMask from 'react-input-mask';
import InputMask from 'react-input-mask';
import withRouter from '../../../withRouter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Address extends Component {

    constructor(props) {
        super(props);
        this.state = {
        btnClick:1,
        checkCep:true,
        data:[],
        cep:'08571160',
        logradouro:'rua machadinho',
        localidade:'',
        numero:'',
        bairro:'Jardim joandra',
        destinatario:'',
        nome:'',
        frete:false,
        load:true,
        checkNmr:'Campo obrigatório.'
        }
      
    
      }

      _BuscaCep(c){
         fetch(`${this.props.host}/_next/api/check-cep/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
           cep:c
            })
          })
          .then(response => response.json())
          .then(data => {
if(data.estado != 'erro'){
   this.setState({
      logradouro:data.logradouro,
      bairro:data.bairro,
      localidade:data.localidade,
      checkCep:true
   })
}
           





          })
      }

componentDidMount(){
   if(this.props.location.state != null){
   this.setState({nome:this.props.location.state.name})
   }
 

   fetch(`${this.props.host}/_next/api/checkout/1/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this.setState({load:false})
     
    if(data.estado == "error"){
   
      this.props.navigateTo('/checkout/')
    }
    })





}

  render() {
   if(this.state.load){
      return(<>
      Carregando...
      </>)
   }
    return (
      <div>
         <section class="">
            
        <div class="container">

       

        <div class="checkout-steps">
   <ul class="multisteps-mbl">
      <li>
         <div class="holder-icon active">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"></path>
            </svg>
         </div>
         <div class="enable">
            <div class="info">Informações</div>
         </div>
      </li>
      <li>
         <div class="holder-icon active">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="truck" class="svg-inline--fa fa-truck " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
               <path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
            </svg>
         </div>
         <div class="enable">
            <div class="info">Entrega</div>
         </div>
      </li>
      <li>
         <div class="holder-icon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="credit-card" class="svg-inline--fa fa-credit-card " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
               <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"></path>
            </svg>
         </div>
         <div class="disable">
            <div class="info">Pagamento</div>
         </div>
      </li>
   </ul>
</div>






        <div class="steps">
       
     
          <div className='info-usr'>
        <div className='head'>
        <div class="titulo">
        Entrega
        </div>
        <span>Cadastre ou selecione um endereço </span> 
        </div>
        <div className='body'>
        
        {this.state.frete == true ? <>
        <div className='nvEdrc' onClick={()=>{
         this.setState({frete:false,checkCep:false,cep:''})
        }}> <FontAwesomeIcon icon={faPlus} /> Novo endereço</div>
        <div className='ListAddr'>
        <div class="edrc">
<div>
   <input type='checkbox'></input>
</div>
<div className='titulo'>
<span>Praça Padre João Álvares, 100 - Centro</span>
<div>Itaquaquecetuba-SP | CEP 08570-050</div>
</div>
<div className='btns-edrc'>
<FontAwesomeIcon icon={faPencil} />
<FontAwesomeIcon icon={faTrash} />
</div>
        </div>
        </div>
        </> : <>
        <div className='area-ipt'>
       <label>CEP:</label>
       <div class="o-ipt">
       <InputMask
       type='tel'
        mask="999.99-999"
        value={this.state.cep}
        onKeyDown={(e)=>{
         if(e.keyCode == 46){
            if(this.state.checkCep == true){
               this.setState({checkCep:false,cep:'',localidade:''})
            }
            return false;
         }
         if(e.keyCode == 8){
            if(this.state.checkCep == true){
               this.setState({checkCep:false,localidade:''})
            }
         }
        }}
        onChange={(e)=>{





         this.setState({cep:e.target.value})
         const cepRegex = /^\d{3}\.\d{2}-\d{3}$/;

         // Verifica se o novo CEP inserido pelo usuário corresponde ao formato esperado
         if(cepRegex.test(e.target.value)){
         
            this._BuscaCep(e.target.value)
         
         }
        }}
        placeholder="000.00-00"
      />
      <div>{this.state.localidade}</div>
       </div>
      
       </div>
       {this.state.checkCep && <>
<div className='edrc'>
<div className='area-ipt'>
       <label>Endereço:</label>
       <div>
       <input onChange={(e)=>{
         this.setState({
            logradouro:e.target.value
         })
       }} value={this.state.logradouro} placeholder=''/>
       </div>
      


      
</div>

<div class="nmr-edrc">
<div className={`area-ipt ar0 ${this.state.checkNmr.length > 0 && 'group-error'}`}>
       <label>Número:</label>
       <div>
       <input onChange={(e)=>{
         this.setState({
           numero:e.target.value,
           checkNmr:''
         })
       }}   onBlur={(e)=>{
         var valorInput = e.target.value;
         if(e.target.value.length == 0){
            this.setState({checkNmr:'Campo obrigatório.'})
         }else{
            if (!/^\d+$/.test(valorInput)) {
               this.setState({checkNmr:'Campo invalido!'})
            }else{
               this.setState({checkNmr:''})
            }
         }
       }}   type="number" placeholder='Ex: 100'/>
       </div>    
       {this.state.checkNmr.length > 0 && <div class="error">{this.state.checkNmr}</div>}
</div>
<div className='area-ipt ar1'>
       <label>Bairro:</label>
       <div>
       <input nChange={(e)=>{
         this.setState({
            bairro:e.target.value
         })
       }} value={this.state.bairro} placeholder=''/>
       </div>    
</div>
</div>

<div className='area-ipt'>
       <label>Complemento:</label>
       <div>
       <input placeholder=''/>
       </div>
      


      
</div>


<div className='area-ipt'>
       <label>Destinatário:</label>
       <div>
       <input onChange={(e)=>{
         this.setState({
            destinatario:e.target.value
         })
       }} value={this.state.nome} placeholder=''/>
       </div>
      


      
</div>
<button className="ctna" onClick={()=>{
   
   if(this.state.checkCep == true){
      const cepRegex = /^\d{3}\.\d{2}-\d{3}$/;
      if(cepRegex.test(this.state.cep)){
      if(this.state.logradouro.trim().length < 2){
         const id = toast.loading("Agaurde...")
         toast.update(id, { render: 'Informe o nome da rua!', type: "error", isLoading: false,autoClose: 3000 });
      }

      fetch(`${this.props.host}/_next/api/checkout/step/2/`, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({
         cep:this.state.cep,
         logradouro:this.state.logradouro,
         numero:this.state.numero,
         bairro:this.state.bairro,
         destinatario:this.state.nome
         })
       })
       .then(response => response.json())
       .then(data => {

       })







      this.setState({frete:true})
      }else{
         const id = toast.loading("Agaurde...")


        
           toast.update(id, { render: 'Verifique o CEP digitado!', type: "error", isLoading: false,autoClose: 3000 });
 
      }
  
   }
}}>Salvar</button>
</div>
       </>}
        </>}
     
       

       </div>
       </div>
        
        </div>
        </div>
      
        </section>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(Address)