import React, { Component } from "react";
import { useLocation,Link,NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VideoModal from "./VideoModal";
import { 
faPlay 
} from '@fortawesome/free-solid-svg-icons'
    class CardProduct extends Component {
      constructor(props) {
        super(props);
        this.state = {
          parametro: "",
          data:[],
          load:true,
          videoProduct:{
            aberto:false
          }
        };
      }
    
      componentDidMount() {
    
      }
    real(valor){
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }
    
    precoVari(vari){
        var min =   vari.reduce((max, b) => Math.min(max, b.valor), vari[0].valor);
        var max =   vari.reduce((max, b) => Math.max(max, b.valor), vari[0].valor);
        return `${this.real(min)} ~ ${this.real(max)}`
      }

      limitaTitulo(str) {
        if (str.length > 50) {
          str = str.substring(0, 50);
          str = str.substring(0, Math.min(str.length, str.lastIndexOf(" ")))+"...";
        }
        return str;
      }

 UltmUnd(arr){
    if(arr.vari_pdt.length > 0){
        var soma = 0;
       arr.vari_pdt[0].data.forEach((item,index)=>{
       soma += new Number(item.qtd)
       });
       if(soma < 8){
        return(  <span class="sale-tag">Últimas unidaes</span>)
       }
    
    }else{
        if(arr.qtd < 8){
            return(  <span class="sale-tag">Últimas unidaes</span>)
           }
    }

 }

      render() {     
         
    
         
        return(<>



{this.props.itens.map((item,index)=>(
    <>
      <div className={`col-lg-${this.props.list != undefined ? this.props.list : '4' } col-md-4 col-12`}>
    
    
    
    <div class="single-product">
    <div class="product-image">
    <NavLink to={`/produto/${item.link_real}`} data-link="">
    <img src={`${this.props.host}/_next/img/${item.img}&qualidade=70`} alt="#"/>
    </NavLink>
    {item.video == true ? <><button class="video" onClick={()=>{
      this.setState({videoProduct:{aberto:true}})
    }}><FontAwesomeIcon icon={faPlay} /></button></> : ''}
    {item.atacado == true ? <><div class="atcd">Atacado</div></> : ''}
{this.UltmUnd(item)}
 
    </div>
     <div class="product-info">
    <span class="category">Categoria: <Link to={`/categoria/${item.categoria.link}`}> {item.categoria.titulo}</Link></span>
    <h4 class="title">
    <Link to={`/produto/${item.link_real}/`} data-link="">{this.limitaTitulo(item.titulo)}</Link>
    </h4>
Produto sem avaliação!
    <div class="price">
        {
            item.vari_pdt.length > 0 ? <>
            <span>{this.precoVari(item.vari_pdt[0].data)}</span>
            </> :
            <><span>{this.real(new Number(item.valor))}</span></>
        }
    <span></span>
    </div>
    </div>
    </div>
    
    </div>
    </>
))}


<VideoModal show={this.state.videoProduct.aberto} />
        
        </>
        
       
      
        
        
        
        
        
        )

      }
}

export default CardProduct;