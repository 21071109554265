import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CardProduct from '../../../../comps/CardProdct';
class TopPdts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Slider:[],
      load:true,
      produtos:[]
    };
  }

  

  componentDidMount() {

    fetch(`${this.props.host}/_next/api/inicio?bloco=TopPdts`)
    .then(response => response.json())
    .then(data => {

      this.setState({ produtos:data,load:false });

    });
    
    
  }

  render() {
    
 


    return (
      <>
      <section class="trending-product section">
<div class="container">

<div class="row" id="NovosProdutos">

<div class="card-header">
  <span>MAIS ACESSADOS</span>
  </div>
  
<CardProduct itens={this.state.produtos} host={this.props.host}/>
 </div>
</div>
</section>
      </>
    );
  }
}

export default TopPdts;