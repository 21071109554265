

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Endereco extends Component {
constructor(props) {
super(props);
this.state = {
data:[]
};
}




componentDidMount(){
    document.title = "Meus endereços - Welessa Eletronic"
    document.title = "Finalizar compra - WeLessa Eletronic"
    fetch(`${this.props.host}/_next/api/lista-enderecos/`)
    .then(response => response.json())
    .then(dados => {
      this.setState({data:dados,load:false})

    })
  }

render() {
  return (
    <>
 


 
    
    
    
            <ToastContainer />
    
    
    </>
    );
}
}
export default Endereco;