

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import 'react-toastify/dist/ReactToastify.css';
class AddEndereco extends Component {
constructor(props) {
super(props);
this.state = {
dados:[],
type:'cep',
cep:'',
load:true,
nome:'paulo',
sobrenome:'cavalcante',
telefone:'(11) 9 8179-9101',
numero:'143',
complemento:'casa',
env:false,
estado:'add'
};
}




componentDidMount(){
    document.title = "Adicionar endereço - Welessa Eletronic"
    var link =   window.location.pathname.split('/adicionar/')[1]
  
  var link =   window.location.pathname.split('/adicionar/')[1]
    

  fetch(`${this.props.host}/_next/api/busca-endereco/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cod:link
    })
  })
    .then(response => response.json())
    .then(data => {
     if(data.estado == "sucesso"){
     this.setState({dados:data.data,load:false})
     }else{

     }
    })





  }

render() {
if(this.state.load == true){
return(<>
Carregando....
</>)
}else{
if(this.state.estado == "add"){
  return(<>
    <div class="account-login section">
            <div class="container">
            <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
            <div class="register-form">
            <div class="title">
            <h3>Adicionar endereço</h3>
           
            </div>
            <div class="row">
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-fn">Nome:</label>
            <input class="form-control" type="text" value={this.state.nome} onChange={(e)=>{
              this.setState({nome:e.target.value})
            }} placeholder="Nome:" required=""/>
            </div>
            </div>
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-ln">Sobrenome:</label>
            <input class="form-control" type="text" value={this.state.sobrenome} onChange={(e)=>{
              this.setState({sobrenome:e.target.value})
            }} placeholder="Sobrenome:" required=""/>
            </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                <label for="reg-ln">Telefone:</label>
                <InputMask class="form-control" value={this.state.telefone} onChange={(e)=>{
              this.setState({telefone:e.target.value})
            }}  mask="(99) 9 9999-9999" placeholder="Telefone:" />
          
                </div>
                </div>
            <div class="col-sm-6">
                <div class="form-group">
                <label for="reg-ln">Cep:</label>
                <input class="form-control" type="text" value={this.state.dados.cep} disabled="true"/>
                </div>
                </div>
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-email">Estado:</label>
            <input class="form-control" type="email" value={this.state.dados.estado} disabled="true"/>
            </div>
            </div>
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-phone">Cidade:</label>
            <input class="form-control" type="text" value={this.state.dados.cidade} disabled="true"/>
            </div>
            </div>
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-pass">Bairro:</label>
            <input class="form-control" type="text" value={this.state.dados.bairro} disabled="true"/>
            </div>
            </div>
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-pass-confirm">Rua:</label>
            <input class="form-control" type="text" value={this.state.dados.rua} disabled="true"/>
            </div>



            
            </div>
            
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-pass-confirm">Numero:</label>
            <input class="form-control" value={this.state.numero} type="text" onChange={(e)=>{
             
             
             if (/^\d*$/.test(e.target.value)) {
              this.setState({numero:e.target.value})
            }
             
   
            }} placeholder="Numero:" required=""/>
            </div>



            
            </div>
            <div class="col-sm-6">
            <div class="form-group">
            <label for="reg-pass-confirm">Complemento:</label>
            <input class="form-control" value={this.state.complemento} type="text" onChange={(e)=>{
              this.setState({complemento:e.target.value})
            }} placeholder="Complemento:" required=""/>
            </div>



            
            </div>
            <div class="button">
            <button class="btn" onClick={()=>{
                


          if(this.state.nome.trim().length == 0){
            toast.error('Preencha o campo de nome!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return false;
          }

          if(this.state.sobrenome.trim().length == 0){
            toast.error('Preencha o campo de sobrenome!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return false;
          }

          if(this.state.telefone.trim().length == 0){
            toast.error('Preencha o campo de telefone!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return false;
          }


          const phoneRegex = /^\(\d{2}\) \d \d{4}-\d{4}$/;

          if (!phoneRegex.test(this.state.telefone)) {
            toast.error('Preencha corretamente o campo de telefone!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return false;
          }


          if(this.state.numero.trim().length == 0){
            toast.error('Preencha o campo de numero!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return false;
          }

          if(this.state.complemento.trim().length == 0){
            toast.error('Preencha o campo de complemento!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return false;
          }

          var link =   window.location.pathname.split('/adicionar/')[1]
 
          const id = toast.loading("busca");
          toast.update(id, { render: "Salvando...", type: "success", isLoading: true });
          this.setState({env:true})
          fetch(`${this.props.host}/_next/api/salvar-endereco/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              cod:link,
              nome:this.state.nome,
              sobrenome:this.state.sobrenome,
              telefone:this.state.telefone,
              numero:this.state.numero,
              complemento:this.state.complemento,
            })
          })
            .then(response => response.json())
            .then(data => {
              this.setState({env:false})
             if(data.estado == "sucesso"){
      this.setState({
        estado:'sucesso'
      })
             }else{
        
             }
            })

            }} disabled={this.state.env}>Enviar</button>
            </div>
            
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <ToastContainer />
</>)
}else if(this.state.estado == "sucesso"){
  return(<>
    <div class="error-area">
    <div class="d-table">
    <div class="ajusta">
    <div class="container">
    <div class="error-content">
    <img class="ErrImg" src={this.props.host+'/_next/static/imagens/compras.svg'} />
    <h2>Endereço salvo
    </h2>
    <p>Seu endereço foi salvo com sucesso e agora você pode aproveitar a facilidade de fazer pedidos de forma rápida e conveniente. </p>
    <div class="button">
    <Link to="/compras/carrinho" class="btn">Meu cariinho</Link>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>)
}  

}
}
}
export default AddEndereco;