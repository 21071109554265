import React, { Component,createRef } from "react";
import { useLocation,Link,NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyContext from "../../comps/context";
import { 
faClose,faPlus,faMinus,faSortDown
} from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
    class Carrinho extends Component {
      constructor(props) {
        super(props);
        this.state = {
       data:[],
       itens:[],
       loader:true,
       activeVari:null,
       activeIpt:null,
       dropVari:null,
       activeOpcVari:null,
       clickVari:null,
       sld:[],
       sdc:'',
       originalListItems: [],
        };
        this.inputRef = createRef();
        this.listaRef = createRef();
        
        this.handleOptionSelect = this.handleOptionSelect.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
      }
    
      handleOptionSelect(option) {
       console.log('fecha')
      }
    
      handleClickOutside(event) {
        if (
          this.listaRef.current &&
          !this.listaRef.current.contains(event.target)
        ) {
        console.log('fecha')
        }
      }

      _Loader(){
        fetch(`${this.props.host}/_next/api/listar-carrinho/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(data => {
          this.setState({loader:false,itens:data.itens,sdc:data.total})

          this.context.updateRmvPdt([{}],3)
        if(data.estado == "sucesso"){
          
        }else{
        
        }
      }
      )
      }
      
      componentDidMount() {
        document.title = "Meu carrinho de compras"
        
 
    

        this._Loader()

        document.addEventListener('mousedown', this.handleClickOutside);
      }
  
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }

      handleClickOutside(event) {
        if (
          this.listaRef.current &&
          !this.listaRef.current.contains(event.target)
        ) {
     this.setState({
      dropVari:-1
     })
        }
      }
    
    real(valor){
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }

      handleChange = (event, index) => {
        var lista = this.state.itens;
        const novaLista = [...lista]; // cria uma nova lista para manter o estado imutável
       if(event.target.value == 0){
        return false;
       }
        novaLista[index].quantidade = parseInt(event.target.value); // atualiza o valor do item na nova lista
        
        
   
        const updatedList = [...this.state.itens]; // Faz uma cópia da lista atual para não mutar o estado diretamente
        updatedList[index] = event.target; // Atualiza o valor do item na lista
       
        this.setState({ lista: novaLista,listItems:updatedList,originalListItems: [...this.state.itens]  }); // atualiza o estado com a nova lista
      }



      atualiza_pdt = (index,qtd,pdt) =>{
       this.setState({activeIpt:index})
       const id = toast.loading("atu")
       toast.update(id, { render: "Atualizando...", type: "success", isLoading: true });



       fetch(`${this.props.host}/_next/api/alterar-pedido/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          pdt,
          qtd:`${qtd}`
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({activeIpt:-1})  
      if(data.estado == "sucesso"){
        toast.update(id, { render: "Quantidade atualizada", type: "success", isLoading: false,autoClose: 3000});
        var lista = this.state.itens;
            const novaLista = [...lista]; 
            novaLista[index].valor_total= data.valor // atualiza o valor do item na nova lista
            this.setState({itens:novaLista})   
         }else if(data.estado == "MaxLimite"){
          toast.update(id, { render: data.msg, type: "warning", isLoading: false,autoClose: 3000});
          var lista = this.state.itens;
          const novaLista = [...lista]; 
          novaLista[index].quantidade = data.max; // atualiza o valor do item na nova lista
          this.setState({itens:novaLista})
        }else if(data.estado == "erro"){
          toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000});
    
      }
    })


       
      }

      atualiza_qtd = (index,qtd,pdt) =>{
        this.setState({activeIpt:index})
        const id = toast.loading("atu")
        toast.update(id, { render: "Atualizando...", type: "success", isLoading: true });
 
 
 
        fetch(`${this.props.host}/_next/api/alterar-pedido/`, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({
           pdt,
           qtd:`${qtd}`
         })
       })
       .then(response => response.json())
       .then(data => {
         this.setState({activeIpt:-1})  
       if(data.estado == "sucesso"){
         toast.update(id, { render: "Quantidade atualizada", type: "success", isLoading: false,autoClose: 3000});
         var lista = this.state.itens;
             const novaLista = [...lista]; 
             novaLista[index].valor_total= data.valor // atualiza o valor do item na nova lista
             this.setState({itens:novaLista})   
          }else if(data.estado == "MaxLimite"){
           toast.update(id, { render: data.msg, type: "warning", isLoading: false,autoClose: 3000});
           var lista = this.state.itens;
           const novaLista = [...lista]; 
           novaLista[index].quantidade = data.max; // atualiza o valor do item na nova lista
           this.setState({itens:novaLista})
         }else if(data.estado == "erro"){
           toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000});
     
       }
     })
 
 
        
       }

      render() {   
          

    
      
      if(true){
        if(this.state.loader == true){
          return(<>
  <div class="shopping-cart section">
  <div class="container">
  <div class="cart-list-head">
  
  <div class="cart-list-title">
  <div class="row">
  <div class="col-lg-1 col-md-1 col-12">
  </div>
  <div class="col-lg-4 col-md-3 col-12">
  <p>Produto</p>
  </div>
  <div class="col-lg-2 col-md-2 col-12">
  <p>Quantidade</p>
  </div>
  <div class="col-lg-2 col-md-2 col-12">
  <p>Preço Unitário</p>
  </div>
  <div class="col-lg-2 col-md-2 col-12">
  <p>Preço Total</p>
  </div>
  <div class="col-lg-1 col-md-2 col-12">
  <p>Remover</p>
  </div>
  </div>
  </div>
  
  
  <div class="cart-single-list">
  <div class="row align-items-center">
  <div class="col-lg-1 col-md-1 col-12">
  <div class="load cart-img" style={{width:85,height:85}}></div>
  </div>
  <div class="col-lg-4 col-md-3 col-12">
  <h5 class="product-name"><a href="product-details.html">
  <div class="load cart-img" style={{width:300,height:15}}></div></a></h5>
  <p class="product-des">
  <span>  <div class="load cart-img" style={{width:175,height:15}}></div></span>
  
  </p>
  
  </div>
  
  
  </div>
  </div>
  
  <div class="cart-single-list">
  <div class="row align-items-center">
  <div class="col-lg-1 col-md-1 col-12">
  <div class="load cart-img" style={{width:85,height:85}}></div>
  </div>
  <div class="col-lg-4 col-md-3 col-12">
  <h5 class="product-name"><a href="product-details.html">
  <div class="load cart-img" style={{width:300,height:15}}></div></a></h5>
  <p class="product-des">
  <span>  <div class="load cart-img" style={{width:175,height:15}}></div></span>
  
  </p>
  
  </div>
  
  
  </div>
  </div>
  
  
  <div class="cart-single-list">
  <div class="row align-items-center">
  <div class="col-lg-1 col-md-1 col-12">
  <div class="load cart-img" style={{width:85,height:85}}></div>
  </div>
  <div class="col-lg-4 col-md-3 col-12">
  <h5 class="product-name"><a href="product-details.html">
  <div class="load cart-img" style={{width:300,height:15}}></div></a></h5>
  <p class="product-des">
  <span>  <div class="load cart-img" style={{width:175,height:15}}></div></span>
  
  </p>
  
  </div>
  
  
  </div>
  </div>
  
  
  <div class="cart-single-list">
  <div class="row align-items-center">
  <div class="col-lg-1 col-md-1 col-12">
  <div class="load cart-img" style={{width:85,height:85}}></div>
  </div>
  <div class="col-lg-4 col-md-3 col-12">
  <h5 class="product-name"><a href="product-details.html">
  <div class="load cart-img" style={{width:300,height:15}}></div></a></h5>
  <p class="product-des">
  <span>  <div class="load cart-img" style={{width:175,height:15}}></div></span>
  
  </p>
  
  </div>
  
  
  </div>
  </div>
  
  
  
  
  </div>
  
  </div>
  </div>
          </>)
           }else{
            
            if(this.state.itens != undefined){
  
              const allVari = [];
            
            return(<div className="area-crr">
   
  
  

  <div class="shopping-cart section">
  <div class="container">
  <div class="cart-list-head">
  
  <div class="cart-list-title">
  <div class="row">
  <div class="col-lg-1 col-md-1 col-12">
  </div>
  <div class="col-lg-4 col-md-3 col-12">
  <p>Produto</p>
  </div>
  <div class="col-lg-2 col-md-2 col-12">
  <p>Quantidade</p>
  </div>
  <div class="col-lg-2 col-md-2 col-12">
  <p>Preço Unitário</p>
  </div>
  <div class="col-lg-2 col-md-2 col-12">
  <p>Preço Total</p>
  </div>
  <div class="col-lg-1 col-md-2 col-12">
  <p>Remover</p>
  </div>
  </div>
  </div>
  
  
  
  {this.state.itens.map((item,index)=>{
  var its = index;
  if(item.variacao.length > 0){
  allVari.push({
    id:item.hash,
    selected:item.variacao[0].v3,
    nome:item.variacao[0].v2
  })
  }
  
  var MyProduct = item;
  
  return(<>
  
  
  
  
    {this.context.myPdtRmv.find(r => r.id === item.hash) ? '' :  <div class={this.state.activeVari === index ? "cart-single-list atlzd" : "cart-single-list"}  key={index}>
      
      <div class="row align-items-center">
      <div class="col-lg-1 col-md-1 col-12">
      <Link to={`/produto/${item.hash}`}><img src={`${this.props.host}/_next/img/${item.imgs.link}&qualidade=70`} alt="#"/></Link>
      </div>
      <div class="col-lg-4 col-md-3 col-12">
      <h5 class="product-name"><Link to={`/produto/${item.hash}`}>{item.titulo}</Link></h5>
      <p class="product-des">
      <span><em>Categoria:</em> <Link to={`/categoria/${item.categoria.link}`}> {item.categoria.titulo}</Link></span>
      {item.variacao.length > 0 &&  <span  onClick={()=>{
       var arr = allVari.filter(obj => obj.selected !== item.variacao[0].v3);
       this.setState({dropVari:index,activeOpcVari:item.variacao[0].v3,sld:arr})
      }}><em>{item.variacao[0].v1}:</em> {item.variacao[0].v2} <FontAwesomeIcon icon={faSortDown} /></span>}
     
    
  
  
    
      </p>
  
    {this.state.dropVari === index && <div class="trocaVaria" ref={this.listaRef}>
    
    
    
    <div class="checkout-steps-form-style-1">
       <div class="col-md-12">
          <div class="checkout-payment-option">
             <h6 class="heading-6 font-weight-400 payment-title">{item.itens_vari[0].nome}:</h6>
             <div class="payment-option-wrapper">
            
         
    
    {item.itens_vari[0].data.map((item,index)=>{
   
  
  
  
  
  
  
     
        return ( <div class={this.state.sld.find(objeto => objeto.selected === item.hash) ? 'single-payment-option atlzd' : 'single-payment-option'}  onClick={()=>{
        
          var check  = this.state.sld.find(objeto => objeto.selected === item.hash);
          if(!check){
          this.setState({
           activeOpcVari:item.hash,
           clickVari:item
          })
        }
         }}>
  
  
         <label class={this.state.activeOpcVari === item.hash ? "active" : ""}>
            <p>{item.name}</p>
            <span class="price">{this.real(new Number(item.valor))}</span>
         </label>
      </div>)
  })}
    
        
            
             </div>
             <div class="button" style={{float:'right',marginTop:10}}>
      
      <button class="btn btn-alt" onClick={()=>{
        this.setState({
          dropVari:-1
        })
      }}>Cancelar</button>
    
      <button class="btn" onClick={()=>{
        if(MyProduct.variacao[0].v3 == this.state.activeOpcVari){
          this.setState({
            dropVari:-1
          })
        }else{
  
  
  var el  = (this.state.clickVari)
  
   
   const id = toast.loading("atu")
   toast.update(id, { render: "Atualizando...", type: "success", isLoading: true });
  
  
    
          fetch(`${this.props.host}/_next/api/atualizar-variacao/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
             id:this.state.itens[its].token,
             vrc:this.state.activeOpcVari
            })
          })
          .then(response => response.json())
          .then(data => {
          
          if(data.estado == "sucesso"){
  toast.update(id, { render: "Variação atualizada!", type: "success", isLoading: false,autoClose: 3000});
  var lista = this.state.itens;
  const novaLista = [...lista]; 
  novaLista[its].variacao = [{v1:item.variacao[0].v1,v2:el.name,v3:el.hash}] 
  novaLista[its].valor = this.real(data.v_u);
  novaLista[its].valor_total = this.real(data.v_t);
  this.setState({itens:novaLista,dropVari:-1})
          }else{
  toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000});
   
          }
        })
  
  
  
  
        }
  
      }}>Confirmar</button>
      </div>
          </div>
     
       </div>
    
    </div>
    
    
    
    
    
    </div>}
      </div>
      <div class="col-lg-2 col-md-2 col-12">
      <div class="count-input">
      <div class={this.state.activeIpt === index ? "input-number atlzd" : "input-number"}  
      
      key={index}>
                                        <input id="iptQtd"  
                                        onChange={(event) => this.handleChange(event, index)} 
                                        onBlur={(e) => {
    
                                    
    
    if(this.state.itens[index] == this.state.originalListItems[index]){
      const id = toast.loading("atu")
      toast.update(id, { render: "Atualizando...", type: "success", isLoading: true });
      this.setState({activeIpt:index})         
      fetch(`${this.props.host}/_next/api/alterar-pedido/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          pdt:item.token,
          qtd:e.target.value
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({activeIpt:-1})  
      if(data.estado == "sucesso"){
        toast.update(id, { render: "Quantidade atualizada", type: "success", isLoading: false,autoClose: 3000});
        var lista = this.state.itens;
            const novaLista = [...lista]; // cria uma nova lista para manter o estado imutável
            novaLista[index].valor_total= data.valor // atualiza o valor do item na nova lista
            this.setState({itens:novaLista})            
      }else if(data.estado == "MaxLimite"){
        toast.update(id, { render: data.msg, type: "warning", isLoading: false,autoClose: 3000});
        
        var lista = this.state.itens;
        const novaLista = [...lista]; // cria uma nova lista para manter o estado imutável
        novaLista[index].quantidade = data.max; // atualiza o valor do item na nova lista
        this.setState({itens:novaLista})
      }else if(data.estado == "erro"){
        toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000});
        
      }
    
    
    
      
    }
    )
    
    }
    
    this.setState({originalListItems:[]})
    
    
                                          //console.log(e.target.value)
                                        }}
                                        value={item.quantidade} type="number"/>
    
                            <div onBlur={()=>{
                             
                            }}>       
                        <span class="qty-up"    onClick={()=>{
                                      
    
    
                                      var lista = this.state.itens;
                                      const novaLista = [...lista]; // cria uma nova lista para manter o estado imutável
                                      var qtd =  (new Number(lista[index].quantidade) + 1); // atualiza o valor do item na nova lista
                                      novaLista[index].quantidade = qtd;
    
                              
                                      this.setState({itens:novaLista})
    
    
    this.atualiza_qtd(index,qtd,item.token)
    
    
    
    
    
                                      
                                        }} ><FontAwesomeIcon icon={faPlus} />
                                        </span></div>
                                        
                                        <span class="qty-down" onClick={()=>{
                                      
    
    
                                      var lista = this.state.itens;
                                      const novaLista = [...lista]; // cria uma nova lista para manter o estado imutável
                                     
                                      
                                      var value = (new Number(lista[index].quantidade) - 1); // atualiza o valor do item na nova lista
                                      if(value < 1){
                                        return false;
                                      }
                                      value = value < 1 ? 1 : value;
                                      novaLista[index].quantidade = value;
                                      this.setState({itens:novaLista})
                                    
                                      this.atualiza_pdt(index,value,item.token)
                                      
                                        }}><FontAwesomeIcon icon={faMinus} />
                                        </span>
                                        
                                    </div>
      </div>
      </div>
      <div class="col-lg-2 col-md-2 col-12">
      <p>{item.valor}</p>
      </div>
      <div class="col-lg-2 col-md-2 col-12">
      <p>{item.valor_total}</p>
      </div>
      <div class="col-lg-1 col-md-2 col-12">
      <button class="remove-item" onClick={() =>{
          const id = toast.loading("Please wait...")
          toast.update(id, { render: "Removendo...", type: "success", isLoading: true });
          this.setState({
          activeVari:index
          })
    
          var idPdt = item.token;
    
          fetch(`${this.props.host}/_next/api/carrinho/rmv/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id:item.token
            })
          })
          .then(response => response.json())
          .then(data => {
          if(data.estado == "sucesso"){
      
              const updatedItems = this.state.itens.filter(item => item.token !== idPdt);
              toast.update(id, { render: "Produto removido do carrinho", type: "success", isLoading: false,autoClose: 3000 });
              this.context.updateVariable(data.itens)
              if(data.itens == 0){
                this.setState({ itens:undefined,activeVari:-1 });
              }else{
                this.setState({ itens: updatedItems,activeVari:-1 });
              }
             
         
            }else{
              toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000 });
           
          }
        }
        )
    
    
      }}><FontAwesomeIcon icon={faClose} /></button>
      </div>
      </div>
      </div> }
    </>)
  
  
  })}
  
  
  
  </div>
  <div class="row">
               <div class="col-12">
               
               <div class="total-amount">
               <div class="row">
               <div class="col-lg-8 col-md-6 col-12">
               <div class="left">
              
               </div>
               </div>
               <div class="col-lg-4 col-md-6 col-12">
               <div class="right">
           <div id="precos"><ul>
              <li>Subtotal do carrinho:
                {this.state.sdc == '' ? <><span>Calculando...</span></> : <><span>{this.real(this.state.sdc)}</span></>}
              </li>
              </ul></div>
               <div class="button">
               <Link to="/compras/finalizar" class="btn">Finalizar compra</Link>
               <Link to="/" class="btn btn-alt">Continuar comprando</Link>
               </div>
               </div>
               </div>
               </div>
               </div>
               
               </div>
               </div>
  </div>
  
  </div>





  <ToastContainer />
            </div>)
            }else{
              return(<>
              <div class="error-area">
    <div class="d-table">
    <div class="ajusta">
    <div class="container">
    <div class="error-content">
    <img class="ErrImg" src={`${this.props.host}/_next/static/imagens/vazio.svg`}/>
    <h2>Oops! O seu carrinho esta vázio!</h2>
    <p>Adicione um item ao seu carrinho e ele aparecerá aqui.</p>
    <div class="button">
    <Link to="/" class="btn">Página inicial</Link>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
              </>)
            }
          
        }
      }


        

        }
}
Carrinho.contextType = MyContext;
export default Carrinho;
