import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCoffee,
    faUser,
    faMagnifyingGlass,
    faBars,
    faShoppingCart,
    faHeart,
    
 } from '@fortawesome/free-solid-svg-icons'

 import MyContext from "../../../../../comps/context";
class IptMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    load:false,
    texto:''
    }
  

  }




 componentDidMount(){
    var txt  = window.location.pathname.split('/busca/')[1];
    if(txt != undefined){
        this.setState({texto:decodeURIComponent(txt)})
    }
   
 }


 handleChange = (event) => {
    this.setState({ texto: event.target.value }); // Atualiza o estado com o valor do input
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
        if (!this.state.texto || this.state.texto.trim().length === 0) {
            return false;
         }
         this.props.navigateTo('/busca/'+this.state.texto)
    }
  }

  render() {

return(<> 
  <header class="header navbar-area">
      <div class="header-middle-mobile">
      <div class="container">
      <div style={{
position:'relative',

            }}>



<div style={{
   
      position:'relative',
      display:'flex',
      alignItems:'center',
  


      
}}>
<div class="logo-home">
<Link to="/">WeLessa</Link>
</div>
<input type="text" placeholder="Busque em WeLessa:" style={{
    width:'100%',
 
     height:35,
     borderWidth:0,
     background:"#fff",
     color:"#000",
     fontSize:15,
     paddingLeft:10,
     borderRadius:5

        }} onKeyUp={(e)=>{


        }}  onClick={()=>{
        
        }}  value={this.state.texto}
        onChange={this.handleChange} onKeyDown={this.handleKeyDown}/>



<div style={{
   position:"absolute",

   right:0,
   padding:10
  
}}>
<FontAwesomeIcon fontSize={20} icon={faMagnifyingGlass} onClick={()=>{
 if (!this.state.texto || this.state.texto.trim().length === 0) {
    return false;
 }
 this.props.navigateTo('/busca/'+this.state.texto)
}} />
</div>


</div>
      
      

    
      </div>
      </div>
      </div>
      </header>







</>)

    
    
  }
}
IptMobile.contextType = MyContext;
export default IptMobile;