

import React, { Component,useEffect } from "react";
import { Link,useNavigate  } from "react-router-dom"
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
 faLock
} from '@fortawesome/free-solid-svg-icons'
import Subppr from "./Subppr";



export default function PPR(self) {
const navigateTo = useNavigate();
return(<>
<Subppr navigateTo={navigateTo}/>
</>)
}
