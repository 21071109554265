import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCoffee,
    faUser,
    faMagnifyingGlass,
    faBars,
    faShoppingCart,
    faHeart,
    
 } from '@fortawesome/free-solid-svg-icons'

 import MyContext from "../../../../../comps/context";
class ItensMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    load:false
    }
  

  }



 componentDidMount(){
    fetch(`/_next/api/DataMenu/`)
    .then(response => response.json())
    .then(data => {
this.context.updateVariable(data.ItensCar)
this.context.setaHistBusca(data.histBusca)
this.setState({
    load:true,
    data:data
})
    })
 }





  render() {

  return(<>
 {this.state.load == false ? <>
   <div class="row align-items-center">
               <div class="col-lg-12 col-md-6 col-12">
                  <div class="nav-inner">
                     <div class="mega-category-menu">
                        <a href="https://www.welessa.com/categorias/" data-link="">
                          
                        <span class="cat-button"><FontAwesomeIcon icon={faBars} />
                        Todas categorias</span>
                        </a>
                        <ul class="sub-category">
                           
                            <li style={{
                              display:'table',
                              margin:'auto'
                            }}>
                             Carregando...
                           </li>
                           
                           
                           

                        </ul>
                     </div>
                     <nav class="navbar navbar-expand-lg">
                        <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                           <ul id="nav" class="navbar-nav ms-auto ajusta-loader">
                              
                             
                             
                             
                           <li class="nav-item load" style={{
                                 width:130,
                                 height:16
                              }}>
                              
                              </li>
                             
                              <li class="nav-item load" style={{
                                 width:130,
                                 height:16
                              }}>
                              
                              </li>
                             
                              <li class="nav-item load" style={{
                                 width:130,
                                 height:16
                              }}>
                              
                              </li>
                             
                              <li class="nav-item load" style={{
                                 width:130,
                                 height:16
                              }}>
                              
                              </li>
                             
                             
                              

                           </ul>
                        </div>
                     </nav>
                  </div>
               </div>
              
            </div></> : <>
            
            
            <div class="row align-items-center">
               <div class="col-lg-12 col-md-6 col-12">
                  <div class="nav-inner">
                     <div class="mega-category-menu">
                        <a href="https://www.welessa.com/categorias/" data-link="">
                          
                        <span class="cat-button"><FontAwesomeIcon icon={faBars} />
                        Todas categorias</span>
                        </a>
                        <ul class="sub-category">
                           
                            <li style={{
                              display:'table',
                              margin:'auto'
                            }}>
                             Carregando...
                           </li>
                           
                           
                           

                        </ul>
                     </div>
                     <nav class="navbar navbar-expand-lg">
                        <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                           <ul id="nav" class="navbar-nav ms-auto">
                              
                           <li class="nav-item">
                           <Link to="/oferta/hoje/">Oferta do dia</Link>
                           </li>
                             
                           {this.state.data.Sgt.map((item,index)=>(<>
                           <li class="nav-item">
                           <Link to={item.link}>{item.titulo}</Link>
                           </li>
                      
                           </>))}
                             
                          

                            
                             
                             
                              

                           </ul>
                        </div>
                     </nav>
                  </div>
               </div>
              
            </div>
            
            
            </>}
  </>)

    
    
  }
}
ItensMenu.contextType = MyContext;
export default ItensMenu;