import React, { Component,useRef } from 'react';

import { Link,useParams } from "react-router-dom"
import withRouter from '../../withRouter';
import CardProduct from '../../comps/CardProdct';
import MyContext from '../../comps/context';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalProduto from './ModalProduto';
import ButtonRipple from './ButtonRipple';
import ImageCarousel from '../../comps/ImageCarousel';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { 
   faPlus,
   faMinus,
   faCartPlus,
   faCartArrowDown,
   faTruck
   
} from '@fortawesome/free-solid-svg-icons'
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoPlayer from '../../comps/VideoPlayer';
import Magnifier from "react-magnifier";
import SliderMobile from '../../comps/SliderMobile';

class Produto extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
          Glr:[],
          data:{},
          load:true,
          DescLong:false,
          imgPdt:'',
          activeButtonIndex:null,
          activeVari:null,
          valorVari:0,
          valorItem:0,
          itemVari:'',
          hasVariation:false,
          codVari:'',
          estoque:undefined,
          QtdCar:1,
          crr:'',
          pdtAtc:false,
          cepFrete:'',
          modal:{
            show:false,
            titulo:'',
            desc:''
          },
          AreaPlay:false,
          erro:false
        };
       
        this.myRef = React.createRef();
      }
     real(valor){
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }
    handleImagem = (event) => {
      const src = event.target.src;
       
       }

     

LoadResult  = (url) =>{
  console.log(url)
  this.setState({load:true})
  fetch(`${this.props.host}/_next/api/detalhe-produto?id=${url}`)
  .then(response => response.json())
  .then(data => {
if(data[0].estado == "erro"){
  document.title = `Welessa - Eletronic`
this.setState({erro:true,load:false})
}else{




  
if(data[0].video.length > 0){
this.setState({AreaPlay:true})
}else{
  this.setState({AreaPlay:false})
}

var ListGlr = [];
data[0].imgs.forEach((item,index)=>{
  ListGlr.push({
    image:`${this.props.host}/_next/img/${item.link}&qualidade=80`
  })
})
//this.setState({Glr:ListGlr})


  this.setState({erro:false})
  document.title = `${data[0].titulo} | Welessa - Eletronic`
  this.setState({data:data,crr:data[0].crr.crr,load:false,imgPdt:`${this.props.host}/_next/img/${data[0].imgs[0].link}&qualidade=100`});
  this.setState({Glr:ListGlr})
  localStorage.setItem('_pdt',data[0].hash)
  if(data[0].variacao.length > 0){
  this.setState({hasVariation:true})
  }else{
  this.setState({hasVariation:false})
  }
}





  });
}


componentDidUpdate(prevProps){
 
if(prevProps.params['*'] != this.props.params['*']){
  var url = window.location.pathname.split('/produto/')[1]
  this.LoadResult(url)
}
}




      componentDidMount() {
        document.title = "Carregando..."

        var url = window.location.pathname.split('/produto/')[1]
       this.LoadResult(url)
   
  

      




      }

   limitarString(str) {
        if (str.length > 255) {
          str = str.substring(0, 255);
          str = str.substring(0, Math.min(str.length, str.lastIndexOf(" ")));
        }
        return str;
      }

  


TotalVari(){
  var total = 0;
this.state.data[0].variacao[0].data.forEach((item,index)=>{
  total+= new Number(item.qtd)
});
  return total;
}

precoVari(vari){
  var min =   vari.reduce((max, b) => Math.min(max, b.valor), vari[0].valor);
  var max =   vari.reduce((max, b) => Math.max(max, b.valor), vari[0].valor);
  return `${this.real(min)} ~ ${this.real(max)}`
}

removerProduct(pdt){

  const id = toast.loading("Please wait...")
  toast.update(id, { render: "Removendo produto do carrinho", type: "success", isLoading: true });





  fetch(`${this.props.host}/_next/api/carrinho/rmv/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id:pdt
    })
  })
  .then(response => response.json())
  .then(data => {
   
  if(data.estado == "sucesso"){
    toast.update(id, { render: "Produto removido do carrinho", type: "success", isLoading: false,autoClose: 3000 });

    this.context.updateVariable(data.itens)
    this.context.updateRmvPdt('')
  this.setState({crr:false,activeVari:-1,codVari:''})
  }else{
    toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000 });

  }
})
}

AdicionarCarrinho(data){
var isMobile = data.mobile;
var encontrado = (this.context.myPdtRmv.find(item => item.id === data.idPdt))
if (encontrado) {
var updatedItems = this.context.myPdtRmv.filter(item => item.id !== data.idPdt);
this.context.updateRmvPdt(updatedItems,3)
}





if(data.variacao == undefined){
 var data = {
    id:data.idPdt,
    qtd:data.QtdCar
  };
}else{
  if(data.variacao[0].codVari == ""){
    toast.error("Selecione uma variação do produto!");
    return false;
  }
var data = {
  id:data.idPdt,
  qtd:data.QtdCar,
  variacao:data.variacao[0].codVari
}
}


const id = toast.loading("Please wait...")
toast.update(id, { render: "Adicionando ao carrinho", type: "success", isLoading: true });

var quant = data.qtd;

fetch(`${this.props.host}/_next/api/carrinho/add/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(data)
})
.then(response => response.json())
.then(data => {
if(data.estado == "sucesso"){
toast.update(id, { render: "Produto adicionado ao carrinho", type: "success", isLoading: false,autoClose: 3000 });
this.context.updateVariable(data.itens)
var ne = (this.state.estoque - quant)
this.setState({crr:true})
if(isMobile){
  this.props.navigateTo('/checkout')
}
}else{
  toast.update(id, { render: data.msg, type: "error", isLoading: false,autoClose: 3000 });
}
})








}

  render() {
    
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


   if(this.state.load == true){
return(<>
    <section class="item-details section">
    <div class="container">
            <div class="top-area">
            <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12">
            <div class="product-images">
            <main id="gallery">
            <div class="container-lupa">
           
           <div class="load ld-img"></div>
            </div>
            <div class="images">
          
            </div>
            </main>
            </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
            <div class="product-info">
            <h2 class="title"><div class="load" style={{height:16}}></div></h2>
            <p class="category">
            <div class="load" style={{height:16,width:200}}></div>
           </p>
           
            <h3 class="price" id="valor">    <div class="load" style={{height:25,width:100}}></div></h3>
           
            <p class="info-text">
              <div class="load" style={{height:16}}></div>
              <div class="load" style={{height:16,marginTop:13}}></div>
              <div class="load" style={{height:16,marginTop:13}}></div>
            </p>
           
           
         
      
            <div>


<div class="col-lg-4 col-md-4 col-12">

<div class="form-group quantity">

<span class="qtd">
<div class="load" style={{height:16,width:100}}></div>
</span>


<div class="input-number">

  <div class="load" style={{height:45,width:190}}></div>                              
                                    
</div>
                                <span id="etq" max="8">
                                <div class="load" style={{height:16,width:100}}></div>
                                </span>               
</div>

</div>

</div>
            <div class="bottom-content">
            <div class="row align-items-end">
            
            <div class="col-lg-4 col-md-4 col-12">
            <div class="button cart-button">
            <div class="load" style={{height:50,width:175}}></div>
            </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
            <div class="button cart-button">
            <div class="load" style={{height:50,width:175}}></div>
            </div>
            
            </div>
            </div>
            
            </div>
            </div>
            </div>
            
            </div>
            
    </div>



</div>


      </section>
  </>)
   }

    if(this.state.erro == true){
      return(<>
        <div class="error-area">
        <div class="d-table">
        <div class="ajusta">
        <div class="container">
        <div class="error-content">
        <img class="ErrImg" src={this.props.host+'/_next/static/imagens/nada.svg'} />
        <h2>Não encontramos esse produto
        </h2>
        <p>
          <ul>
            <li>É só realizar uma nova busca</li>
            <li>Utilizar um dos nossos links</li>
            <li>Ou voltar para a página inicial</li>
          </ul>
        </p>
        <div class="button">
        <Link to="/" class="btn">Página inicial</Link>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </>)
    }
   



    return (
      <>
      <BrowserView>
      <section class="item-details section">
            <div class="container">
            <div class="top-area">
            <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12">
            <div class="product-images">
            <main id="gallery">
             
          
            <div class="container-lupa">

            {this.state.AreaPlay == true ? <VideoPlayer src={this.state.data[0].video} host={this.props.host}/> : <> 
            
           <div onClick={()=>{
          
           }}>

           <Magnifier src={`${this.state.imgPdt}`} />
           
          
           </div>
             
             
             </>}
          

            </div>
            <div class="images">
            {this.state.data[0].imgs.map((item,index)=>(
            <><img  key={index}  onClick={(event)=>{
            if(this.state.AreaPlay == true){
           this.setState({AreaPlay:false})
            }
            this.setState({imgPdt:event.target.src,activeButtonIndex:index})
            }} src={`${this.props.host}/_next/img/${item.link}&qualidade=80`} class={`img ${this.state.activeButtonIndex === index ? "active" : ""}`} id="img-pdt" alt="#"/></>
            ))}
            </div>
            </main>
            </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
            <div class="product-info">
            <h2 class="title">{this.state.data[0].titulo}</h2>
            <p class="category"><i class="fa fa-tag" aria-hidden="true"></i>
            Categoria: <Link  to={`/categoria/${this.state.data[0].categoria.link}`}>{this.state.data[0].categoria.titulo}</Link></p>
            
{this.state.data[0].atacado != false &&  
<p class="category" style={{ color: this.state.pdtAtc == true ? 'rgb(13, 110, 253)' : '' }}>
 A partir de { ( this.state.data[0].atacado.min)} peças: {(this.real(new Number(this.state.data[0].atacado.valor)))} a unidade
</p>}

{this.state.valorItem > 0 ?  <h3 class="price" id="valor">{this.real(this.state.valorItem)}</h3> : this.state.valorVari > 0 ? <><h3 class="price" id="valor">{this.real(new Number(this.state.valorVari))}</h3></> : this.state.data[0].variacao.length > 0 ? <><h3 class="price" id="valor">{this.precoVari(this.state.data[0].variacao[0].data)}</h3></> :  <h3 class="price" id="valor">{this.real( new Number(this.state.data[0].valor))}</h3>}




           
            
          

{this.state.data[0].variacao.length > 0 ? <>
  <div class="checkout-steps-form-style-1">
     
        
     <div class="col-md-12">
     <div class="checkout-payment-option">
     <h6 class="heading-6 font-weight-400 payment-title">{this.state.data[0].variacao[0].nome}:</h6>
     <div class="payment-option-wrapper">
    
    
        
     
       {this.state.data[0].variacao[0].data.map((item,index)=>(<>
<div key={index} class={`single-payment-option`}  onClick={(e) => {
this.setState({activeVari:index,itemVari:item.hash,estoque:item.qtd,QtdCar:1,valorItem:0,valorVari:(item.valor),codVari:item.hash})
}}>
 <label class={this.state.activeVari === index ? "active" : ""}>
 <p>{item.name}</p>
 <span class="price">{this.real(new Number(item.valor))}</span>
 </label>
</div>
</>))}

 

  

 
 
 
     
 
     </div>
     </div>
     </div>
   
    
     </div>
</> : ''}

       



         
       
            <div>


<div class="col-lg-4 col-md-4 col-12">

<div class="form-group quantity">

<span class="qtd">Quantidade:</span>


<div class="input-number">
                                    <input id="iptQtd" value={this.state.QtdCar} type="number"/>
                                   
                                    <span class="qty-up" onClick={()=>{
                                      var n  = this.state.QtdCar+1;

                                     if(this.state.data[0].variacao.length > 0){
if(this.state.valorVari > 0){
  if(n <= this.state.estoque){
    this.setState({QtdCar:n})
  }
}else{
if(n <= this.TotalVari()){
  this.setState({QtdCar:n})
}
}
}else{
  if(n <= this.state.data[0].qtd){


    if(this.state.data[0].atacado != false){
      if(n >= this.state.data[0].atacado.min){
      this.setState({pdtAtc:true})
      var nv = (n * new Number(this.state.data[0].atacado.valor))
      this.setState({QtdCar:n,valorItem:nv})
      }else{
        var nv = (n * new Number(this.state.data[0].valor))
      this.setState({QtdCar:n,valorItem:nv})
      }
    }else{
      var nv = (n * new Number(this.state.data[0].valor))
      this.setState({QtdCar:n,valorItem:nv})
    }



  
  
  }                                 
}

                                     /*
                                    if(this.state.valorVari > 0){
                                    var nv = (n * this.state.valorVari)
                                    console.log(n)
                                    this.setState({QtdCar:n,valorItem:nv})
                                     }else{
                                    this.setState({QtdCar:n})
                                     }
                                     */
                                    }}><FontAwesomeIcon icon={faPlus} />
                                    </span>
                                    
                                    <span class="qty-down" onClick={()=>{
                                    
                                      var n  = this.state.QtdCar-1;
                                      n = n < 1 ? 1 : n;

                                      if(this.state.estoque != undefined){
                                        console.log('com variacao')
                                         }else{
                                      

                                          if(this.state.data[0].atacado != false){
                                            if(n <= this.state.data[0].atacado.min){
                                            this.setState({pdtAtc:false})
                                            var nv = (n * new Number(this.state.data[0].atacado.valor))
                                            this.setState({QtdCar:n,valorItem:nv})
                                            }else{
                                              var nv = (n * new Number(this.state.data[0].valor))
                                            this.setState({QtdCar:n,valorItem:nv})
                                            }
                                          }else{
                                            var nv = (n * new Number(this.state.data[0].valor))
                                            this.setState({QtdCar:n,valorItem:nv})
                                          }
                                      



                                            }
                                      
                                      if(this.state.valorVari > 0){
                                        var nv = (n * this.state.valorVari)
                                        this.setState({QtdCar:n,valorItem:nv})
                                         }else{
                                          this.setState({QtdCar:n})
                                         }

                                    }}><FontAwesomeIcon icon={faMinus} />
                                    </span>
                                    
                                </div>
                                
                                <span id="etq">Estoque: {this.state.estoque != undefined ? <>{this.state.estoque}</> : <>{this.state.data[0].variacao.length > 0 ? this.TotalVari() : this.state.data[0].qtd }</>} </span>               
</div>

</div>

</div>
            <div class="bottom-content">
            <div class="row align-items-end">
            
            <div class="col-lg-4 col-md-4 col-12">
            <div class="button cart-button">
          
          
          
          
          
          
<ButtonRipple className="btn" onClick={()=>{
   this.AdicionarCarrinho({
    mobile:false,
    variacao:this.state.hasVariation == true ? [{
      hasVariation:true,
      codVari:this.state.codVari
    }] : undefined,
    idPdt:this.state.data[0].hash,
    QtdCar:this.state.QtdCar
  }) 
}}>
 <FontAwesomeIcon icon={faCartPlus} /> Adicionar no carrinho
</ButtonRipple>

           
            </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
            <div class="button cart-button">
            <button class="btn btn-alt" id="cpagr"  onClick={()=>{
         

            }}><i class="fa fa-shopping-cart" aria-hidden="true"></i>
             Compre agora</button>
            </div>
            
            </div>
            </div>
       
            </div>
            </div>
            </div>
            
            </div>
            
            </div>


{this.state.data[1].length > 0 ? <>

  <div id="rlc">
  
  <div class="container">
                <div class="row" id="NovosProdutos">
                <div class="card-header"><span>SIMILARES</span></div>
                <CardProduct itens={this.state.data[1]} list={3} host={this.props.host}/>
                </div>
</div>
                </div>
  



</> : <></>}


  

  
 





            <div id="product-details-info" class="product-details-info">
            <div class="single-block">
            <div class="row">
            <div class="col-lg-6 col-12">
            <div class="info-body custom-responsive-margin">
            <h4>Descrição</h4>
            <p>{this.state.data[0].desc}</p>
            
            
           
            </div>
            </div>
            <div class="col-lg-6 col-12">
            <div class="info-body">
            <h4>Especificações</h4>
            <ul class="normal-list">
            <li><span>Altura:</span> {this.state.data[0].espec.altu} cm</li>
            <li><span>Comprimento:</span> {this.state.data[0].espec.comp} cm</li>
            <li><span>Largura:</span> {this.state.data[0].espec.larg} cm</li>
            <li><span>Peso:</span> {this.state.data[0].espec.peso}</li>
            
            </ul>
           
            
           

       
       

<div id="area_frete"></div>


            </div>
            </div>
            </div>
            </div>
            <div class="row">
            <div class="col-lg-4 col-12">
            <div class="single-block give-review">
            <h4>Sem avaliações</h4>
            <ul>
            <li>
            <span>5 Estrelas - 0</span>

            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>

            </li>
            <li>
            <span>4 Estrelas - 0</span>
           
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>

            </li>
            <li>
            <span>3 Estrelas - 0</span>
          
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
          
            </li>
            <li>
            <span>2 Estrelas - 0</span>
            
            <i class="fa fa-star" aria-hidden="true"></i>
            <i class="fa fa-star" aria-hidden="true"></i>
          
            </li>
            <li>
            <span>1 Estrela - 0</span>
          
            <i class="fa fa-star" aria-hidden="true"></i>
            
            </li>
            </ul>
            
            <button type="button" class="btn review-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Deixe um comentário
            </button>
            </div>
            </div>
            <div class="col-lg-8 col-12">
            <div class="single-block">
            <div class="reviews">
            <h4 class="title">Últimas avaliações</h4>
            
            
            
            
            Não há comentários
            
            
           
            
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            
<div class="position-fixed top-0 end-0 p-3" >
<div id="PdtAdd" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="me-auto">WeLessa</strong>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body" id="msgPdt">
    Hello, world! This is a toast message.
  </div>
</div>
</div>



<div class="modal fade" id="ErroLogin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Atenção</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body" id="MsgErro">

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
    </div>
  </div>
</div>
</div>


      </section>
      </BrowserView>
      <MobileView>
      <section class="item-details-mbl section">
            <div class="container">
            <div class="top-area">
            <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12 col-12">
            <div class="area-sld">
         
         <SliderMobile host={this.props.host} slides={this.state.Glr} interval={5000}/>
         </div>
            <div class="product-info">
        
            
            <h2 class="title">{this.state.data[0].titulo}</h2>
            
{this.state.data[0].atacado != false &&  
<p class="category" style={{ color: this.state.pdtAtc == true ? 'rgb(13, 110, 253)' : '' }}>
 A partir de { ( this.state.data[0].atacado.min)} peças: {(this.real(new Number(this.state.data[0].atacado.valor)))} a unidade
</p>}

{this.state.valorItem > 0 ?  <h3 class="price" id="valor">{this.real(this.state.valorItem)}</h3> : this.state.valorVari > 0 ? <><h3 class="price" id="valor">{this.real(new Number(this.state.valorVari))}</h3></> : this.state.data[0].variacao.length > 0 ? <><h3 class="price" id="valor">{this.precoVari(this.state.data[0].variacao[0].data)}</h3></> :  <h3 class="price" id="valor">{this.real( new Number(this.state.data[0].valor))}</h3>}




           
          

{this.state.data[0].variacao.length > 0 && <>
  <div class="checkout-steps-form-style-1">
     
        
     <div class="col-md-12">
     <div class="area-vari">
     <h6 class="heading-6 font-weight-400 payment-title">{this.state.data[0].variacao[0].nome}:</h6>
     <div class="variacao">
    
    
        
     
       {this.state.data[0].variacao[0].data.map((item,index)=>(<>
<div key={index} class={`item`}  onClick={(e) => {
this.setState({activeVari:index,itemVari:item.hash,estoque:item.qtd,QtdCar:1,valorItem:0,valorVari:(item.valor),codVari:item.hash})
}}>
 <label class={this.state.activeVari === index ? "active" : ""}>
 <p>{item.name}</p>
 
 </label>
</div>
</>))}

 

  

 
 
 
     
 
     </div>
     </div>
     </div>
   
    
     </div>
</>}

       



         
       
<div>




</div>
         


<div class="product-form">
<div class="qtd-slc">
<div id="rmv" onClick={()=>{
                                    
                                    var n  = this.state.QtdCar-1;
                                    n = n < 1 ? 1 : n;

                                    if(this.state.estoque != undefined){
                                      console.log('com variacao')
                                       }else{
                                    

                                        if(this.state.data[0].atacado != false){
                                          if(n <= this.state.data[0].atacado.min){
                                          this.setState({pdtAtc:false})
                                          var nv = (n * new Number(this.state.data[0].atacado.valor))
                                          this.setState({QtdCar:n,valorItem:nv})
                                          }else{
                                            var nv = (n * new Number(this.state.data[0].valor))
                                          this.setState({QtdCar:n,valorItem:nv})
                                          }
                                        }else{
                                          var nv = (n * new Number(this.state.data[0].valor))
                                          this.setState({QtdCar:n,valorItem:nv})
                                        }
                                    



                                          }
                                    
                                    if(this.state.valorVari > 0){
                                      var nv = (n * this.state.valorVari)
                                      this.setState({QtdCar:n,valorItem:nv})
                                       }else{
                                        this.setState({QtdCar:n})
                                       }

}}> <FontAwesomeIcon icon={faMinus} /> </div>
<div id="iptQtd" type="number">{this.state.QtdCar}</div>
<div id="add" onClick={()=>{
                                      var n  = this.state.QtdCar+1;

                                     if(this.state.data[0].variacao.length > 0){
if(this.state.valorVari > 0){
  if(n <= this.state.estoque){
    this.setState({QtdCar:n})
  }
}else{
if(n <= this.TotalVari()){
  this.setState({QtdCar:n})
}
}
}else{
  if(n <= this.state.data[0].qtd){


    if(this.state.data[0].atacado != false){
      if(n >= this.state.data[0].atacado.min){
      this.setState({pdtAtc:true})
      var nv = (n * new Number(this.state.data[0].atacado.valor))
      this.setState({QtdCar:n,valorItem:nv})
      }else{
        var nv = (n * new Number(this.state.data[0].valor))
      this.setState({QtdCar:n,valorItem:nv})
      }
    }else{
      var nv = (n * new Number(this.state.data[0].valor))
      this.setState({QtdCar:n,valorItem:nv})
    }



  
  
  }                                 
}

                               


                                    if(this.state.valorVari > 0){
                                    var nv = (n * this.state.valorVari)
                                   
                                    this.setState({QtdCar:n,valorItem:nv})
                                     }else{
                                    this.setState({QtdCar:n})
                                     }
                                     
}}> <FontAwesomeIcon icon={faPlus} /> </div>
</div>
<div class="btns">
<ButtonRipple className="btn-cpa" onClick={()=>{
   this.AdicionarCarrinho({
    mobile:true,
    variacao:this.state.hasVariation == true ? [{
      hasVariation:true,
      codVari:this.state.codVari
    }] : undefined,
    idPdt:this.state.data[0].hash,
    QtdCar:this.state.QtdCar
  }) 
}}>
 COMPRAR AGORA
</ButtonRipple>  
</div>
</div>

           
           
            </div>
           
            </div>
            
            </div>
            
            </div>





  

  
 





            <div id="product-details-info-mbl" class="product-details-info-mbl">
            <div class="single-block-mbl">
            <div class="row">
            <div class="col-lg-6 col-12">
            <div class="info-body-mobile custom-responsive-margin">
            <h4>Descrição</h4>
            <p>{this.state.data[0].desc}</p>
            
            
           
            </div>
            </div>
          
            </div>
            </div>
           
            </div>
            </div>
            
<div class="position-fixed top-0 end-0 p-3" >
<div id="PdtAdd" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="me-auto">WeLessa</strong>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body" id="msgPdt">
    Hello, world! This is a toast message.
  </div>
</div>
</div>



<div class="modal fade" id="ErroLogin" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Atenção</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body" id="MsgErro">

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
    </div>
  </div>
</div>
</div>


      </section>
      </MobileView>
    
    <Modal show={this.state.modal.show} onHide={()=>{
        this.setState({modal:{show:false}})
      }}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.modal.titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{this.state.modal.desc}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" >
          Close
        </Button>
        <Button variant="primary">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
    <ToastContainer />
      </>
    );



  
  }
}
Produto.contextType = MyContext;
export default withRouter(Produto);