import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faUser,
    faTruck,
    faCreditCard,
    faArrowUp,
    faAngleUp,
    faCheck
    } from '@fortawesome/free-solid-svg-icons'
import ButtonRipple from '../Produto/ButtonRipple';
import ReactInputMask from 'react-input-mask';
import InputMask from 'react-input-mask';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class FnzCp extends Component {

    constructor(props) {
        super(props);
        this.state = {
        btnClick:0,
        checkCep:true,
        nome:'joao paulo',
        checkNome:'',
        email:'joao@paulo.com',
        checkEmail:'',
        cpf:'40992601860',
        checkCpf:'',
        celular:'11 959672527',
        checkCelular:'',
        data:[],
        load:true,
        OpenDrop:true
        }
      
    
      }

      componentDidMount(){
        fetch(`${this.props.host}/_next/api/listar-carrinho/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(data => {
           
            if(data.estado == "erro"){
               this.props.navigateTo('/compras/carrinho/')
               this.setState({load:true})
               
            }else{
               this.setState({
                  data,
                  load:false
               })
   
               if(data.compra.length > 0){
                  this.setState({
                     nome:data.compra[0].nome,
                     email:data.compra[0].email,
                     cpf:data.compra[0].cpf,
                     celular:data.compra[0].celular
                  })
               }
            }
            
          })
          
      }
      real(valor){
         return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
     }
      ValidarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      }
      
     ValidarCPF(cpf) {
        // Remove caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');
    
        // Verifica se o CPF tem 11 dígitos
        if (cpf.length !== 11) {
            console.log("CPF deve conter 11 dígitos");
            return false;
        }
    
        // Verifica se todos os dígitos são iguais
        if (/^(\d)\1+$/.test(cpf)) {
            console.log("CPF inválido (todos os dígitos iguais)");
            return false;
        }
    
        // Calcula os dígitos verificadores
        var soma = 0;
        for (var i = 0; i < 9; i++) {
            soma += parseInt(cpf.charAt(i)) * (10 - i);
        }
    
        var resto = soma % 11;
        var digito1 = resto < 2 ? 0 : 11 - resto;
    
        soma = 0;
        for (var i = 0; i < 10; i++) {
            soma += parseInt(cpf.charAt(i)) * (11 - i);
        }
    
        resto = soma % 11;
        var digito2 = resto < 2 ? 0 : 11 - resto;
    
        // Verifica se os dígitos calculados são iguais aos dígitos informados
        if (parseInt(cpf.charAt(9)) !== digito1 || parseInt(cpf.charAt(10)) !== digito2) {
            console.log("CPF inválido (dígitos verificadores incorretos)");
            return false;
        }
    
        console.log("CPF válido");
        return true;
    }
      
    PhoneCheck = (numero) =>{
      console.log(numero)
    var regexCelular = /^\(\d{2}\)\s9\s\d{4}-\d{4}$/;
  
      // Testa o formato do número de celular
     return regexCelular.test(numero)
    }

 

  render() {


if(this.state.load){
   return(<>
   Carregando...
   </>)
}



    return (
      <div>
         <section class="">
            
        <div class="container">

       

        <div class="checkout-steps">
   <ul class="multisteps-mbl">
      <li>
         <div class="holder-icon active">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"></path>
            </svg>
         </div>
         <div class="enable">
            <div class="info">Informações</div>
         </div>
      </li>
      <li>
         <div class="holder-icon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="truck" class="svg-inline--fa fa-truck " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
               <path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
            </svg>
         </div>
         <div class="disable">
            <div class="info">Entrega</div>
         </div>
      </li>
      <li>
         <div class="holder-icon">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="credit-card" class="svg-inline--fa fa-credit-card " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
               <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"></path>
            </svg>
         </div>
         <div class="disable">
            <div class="info">Pagamento</div>
         </div>
      </li>
   </ul>
</div>






        <div class="steps">
        {this.state.btnClick === 0 && (<>
       {this.state.OpenDrop  && <>
         <div className='resumo' onClick={()=>{
         this.setState({OpenDrop:false})
        }}>
            <div className='detalhe'>
            <div class="rsm">Resumo ({this.state.data.itens.length})</div>
            <p>Informações da sua compra</p>
            </div>
        <div className='valor'>
<div>{this.real(this.state.data.total)}</div>
        </div>
        
        </div>
       </>}
       {this.state.OpenDrop == false && <>
         <div className='info-product'>
         <div className='head' onClick={()=>{
            this.setState({
               OpenDrop:true
            })
         }}>
        <span class="titulo">
        Resumo ({this.state.data.itens.length}) 
        </span>
        <FontAwesomeIcon icon={faAngleUp} />
        </div>
       <div className='body'>
       {this.state.data.itens.map((item,index)=><div class="item">
        <img src={`${this.props.host}/_next/img/${item.imgs.link}&qualidade=70`} alt="#"/>
       <div className='detalhes'>
       <div className='titulo'>{item.titulo}</div>
       {item.variacao.length > 0 && <div class="vrc">
       <label>Variação:</label>
       <span>{(item.variacao[0].v1)}</span>
       </div>}
        <div className='valor'>{this.real(item.valor)}</div>
       </div>
       
        </div>)}
       </div>
       <div class="total">
       <span className='text'>
         Total:
       </span>
       <span className='valor'>
      {this.real(this.state.data.total)}
       </span>
       </div>
         </div>
       </>}

        <div className='info-usr'>
        <div className='head'>
        <div class="titulo">
        Identifique-se  
        </div>
        <span>Utilizaremos seu e-mail para: Identificar seu perfil, histórico de compra, notificação de pedidos e carrinho de compras. </span> 
        </div>
        <div className='body'>
        
       <div className={`area-ipt ${this.state.checkNome.length > 0 && 'group-error'}`}>
       <label>Nome completo:</label>
       <div>
       <input value={this.state.nome} onBlur={(e)=>{
             const palavras = e.target.value.trim().split(' ');
   if(e.target.value.length == 0){
      this.setState({checkNome:'Campo obrigatório.'})
   }else{

  

      if (palavras.length < 2 || palavras.some(palavra => palavra.length < 2)) {
         this.setState({checkNome:'Digite o seu nome completo'})  
      }else{
         this.setState({checkNome:false})
      }




      
   }
       }} onChange={(e)=>{
       this.setState({nome:e.target.value,checkNome:false})
       }} placeholder='ex: José Da silva'/>
      
       </div>
       {this.state.checkNome.length > 0 && <div class="error">{this.state.checkNome}</div>}
       
       </div>


       <div className={`area-ipt ${this.state.checkEmail.length > 0 && 'group-error'}`}>
       <label>E-mail:</label>
       <div>
       <input value={this.state.email} onChange={(e)=>{
         this.setState({checkEmail:''})
       this.setState({email:e.target.value})
       }} onBlur={(e)=>{
         var val  = e.target.value;
         if(val.trim().length == 0){
            this.setState({checkEmail:'Campo obrigatório.'})
         }else{
         if(!this.ValidarEmail(val)){
            this.setState({checkEmail:'E-mail inválido. Verifique se digitou corretamente'})
         }else{
            this.setState({checkEmail:''})
         }
         }
       }} placeholder='ex: meu@email.com'/>
       </div>
      
       {this.state.checkEmail.length > 0 && <div class="error">{this.state.checkEmail}</div>}
       

      
       </div>




       <div className={`area-ipt ${this.state.checkCpf.length > 0 && 'group-error'}`}>
       <label>CPF:</label>
       <div>
       <InputMask
       type='tel'
        mask="999.999.999-99"
        value={this.state.cpf} onChange={(e)=>{
          this.setState({cpf:e.target.value,checkCpf:''})
          }} onBlur={(e)=>{
            var val  = e.target.value;
            if(val.trim().length == 0){
               this.setState({checkCpf:'Campo obrigatório.'})
            }else{
 if(!this.ValidarCPF(val)){
   this.setState({checkCpf:'Campo inválido.'})
 }else{
   this.setState({checkCpf:''})
 }
            }}}
      
        placeholder="000.000.000-00"
      />
       </div>
       {this.state.checkCpf.length > 0 && <div class="error">{this.state.checkCpf}</div>}
       </div>


       <div className={`area-ipt ${this.state.checkCelular.length > 0 && 'group-error'}`}>
       <label>Celular / WhatsApp:</label>
       <div>
       <InputMask
       type='tel'
        mask="(99) 9 9999-9999"
        value={this.state.celular} onChange={(e)=>{
          this.setState({celular:e.target.value,checkCelular:''})
          }} onBlur={(e)=>{
            var val  = e.target.value;
            if(val.trim().length == 0){
               this.setState({checkCelular:'Campo obrigatório.'})
            }else{
if(!this.PhoneCheck(val)){
   this.setState({checkCelular:'Campo inválido.'})
}else{
   this.setState({checkCelular:''})
}
            }}}
        placeholder="(99) 9 9999-9999"
      />
       </div>
       {this.state.checkCelular.length > 0 && <div class="error">{this.state.checkCelular}</div>}
       </div>





        </div>
        <button className="ctna" onClick={()=>{
        const id = toast.loading("Please wait...")


        const inputValue =this.state.nome.trim();
        const nameArray = inputValue.split(' ');
      
        if (!(inputValue && nameArray.length === 2 && nameArray.every(name => name.length >= 2))) {
          
          toast.update(id, { render: 'Digite o seu nome completo', type: "error", isLoading: false,autoClose: 3000 });

return false;        
        } 


if(!this.ValidarEmail(this.state.email)){
  toast.update(id, { render: 'E-mail inválido. Verifique se digitou corretamente', type: "error", isLoading: false,autoClose: 3000 });
  return false;
}


if(!this.ValidarCPF(this.state.cpf.replace(/\D/g, ''))){
  toast.update(id, { render: 'Campo de CPF inválido!', type: "error", isLoading: false,autoClose: 3000 });
  return false;
}



if(!this.PhoneCheck(this.state.celular)){
  toast.update(id, { render: 'Número de celular inválido!', type: "error", isLoading: false,autoClose: 3000 });
  return false;
}

fetch(`${this.props.host}/_next/api/checkout/step/1/`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify({
   nome:this.state.nome,
   email:this.state.email,
   cpf:this.state.cpf,
   celular:this.state.celular
   })
 })
 .then(response => response.json())
 .then(data => {
if(data.estado == "sucesso"){
   this.props.navigateTo('/checkout/address',{
    state:{
      name:this.state.nome
    }
   })
}
 })
        


           //
        }}>Continuar</button>
        </div>
        
        </>) }
        
        </div>
        </div>
      
        </section>
        <ToastContainer />
      </div>
    );
  }
}

export default FnzCp