import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link,useNavigate  } from "react-router-dom"
import { 
   faArrowDown,
   
} from '@fortawesome/free-solid-svg-icons'
export default function Footer() {
return (
<>

<footer class="footer">
         <div class="footer-top">
            <div class="container">
               <div class="inner-content">
                  <div class="row">
                     <div class="col-lg-3 col-md-4 col-12">
                        <div class="footer-logo">
                        </div>
                     </div>
                     <div class="col-lg-9 col-md-8 col-12">
                        <div class="footer-newsletter">
                           <h4 class="title">
                              Assine a nossa newsletter
                              <span>Obtenha todas as informações, vendas e ofertas mais recentes.</span>
                           </h4>
                           <div class="newsletter-form-head">
                              <form action="#" method="get" target="_blank" class="newsletter-form">
                                 <input name="EMAIL" placeholder="Email address here..." type="email"/>
                                 <div class="button">
                                    <button class="btn">Subscribe<span class="dir-part"></span></button>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="footer-middle">
            <div class="container">
               <div class="bottom-inner">
                  <div class="row">
                     <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-footer f-contact">
                           <h3>Entre em contato conosco</h3>
                           <p class="phone"><i class="fa fa-whatsapp"></i> (+55) 11 9 8179-9101</p>
                           <ul>
                              <li><span>Seg-Sex: </span> 8:00 - 18:00</li>
                              <li><span>Sabado: </span> 10:00 - 14:00</li>
                           </ul>
                           <p class="mail">
                              <a href="contato@welessa.com"><span class="__cf_email__" data-cfemail="f5868085859a8781b5869d9a8592879c9186db969a98">contato@welessa.com</span></a>
                           </p>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-footer our-app">
                           <h3>Nosso aplicativo</h3>
                           <ul class="app-btn">
                              <li>
                                 <Link to="/app/baixar/">
                                 <FontAwesomeIcon icon={faArrowDown} />
                                 <span class="small-title">Baixe agoras</span>
                       
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-footer f-link">
                           <h3>Informações</h3>
                           <ul>
                              <li><a href="javascript:void(0)">Sobre nós</a></li>
                              <li><a href="javascript:void(0)">Entre em conato</a></li>
                              <li><a href="javascript:void(0)">Downloads</a></li>
                              <li><a href="javascript:void(0)">Mapa do site</a></li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-footer f-link">
                           <h3>Shop Departments</h3>
                           <ul>
                              <li><a href="javascript:void(0)">Computadores e Acessórios</a></li>
                              <li><a href="javascript:void(0)">Smartphones &amp; tablets.</a></li>
                              <li><a href="javascript:void(0)">TV, Vídeo &amp; Audio</a></li>
                              <li><a href="javascript:void(0)">Cameras, fotos &amp; Vídeo</a></li>
                              <li><a href="javascript:void(0)">Fones de ouvido</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="footer-bottom">
            <div class="container">
               <div class="inner-content">
                  <div class="row align-items-center">
                     <div class="col-lg-4 col-12">
                        <div class="payment-gateway">
                           <span>Nós aceitamos:</span>
                           <i class="fa fa-cc-visa" aria-hidden="true"></i>
                           <i class="fa fa-cc-mastercard" aria-hidden="true"></i>
                        </div>
                     </div>
                     <div class="col-lg-4 col-12">
                        <div class="copyright">
                           <p>WeLessa - Eletronic: Praça Padre João Álvares 119, Box 6 - Itaquaquecetuba CNPJ: 42.755.467/0001-91 
                           Todos os direitos reservados.
                           2023 </p>
                        </div>
                     </div>
                     <div class="col-lg-4 col-12">
                        <ul class="socila">
                           <li>
                              <span>Siga-nos em:</span>
                           </li>
                           <li><a href="https://www.facebook.com/WelessaEletronic/?ref=welessa" target="_blank"><i class="fa fa-facebook"></i></a></li>
                           <li><a href="javascript:void(0)"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>

</>
);
}
