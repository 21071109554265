import React from "react";
import { Link } from "react-router-dom"

export default function Afiliados(props) {
return (
<>
<div class="error-area">
<div class="d-table">
<div class="center-pg">
<div class="container">
<div class="error-content">
<img class="ErrImg" src={props.host+'/_next/static/imagens/afl.png'}/>
<h2>Programa de Afiliados: Ganhe Dinheiro Divulgando Nossos Produtos!</h2>
<p>Junte-se ao nosso programa de afiliados e comece a lucrar com cada venda que você ajuda a gerar! Compartilhe nossos produtos incríveis e ganhe uma generosa porcentagem em comissões. É fácil, lucrativo e uma maneira inteligente de aumentar sua renda online. Inscreva-se agora e comece a ganhar dinheiro enquanto promove produtos de qualidade!</p>
<div class="button">
<Link to="/" class="btn">Seja um afiliado</Link>
</div>
</div>
</div>
</div>
</div>
</div>
</>
);
}
