

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
 faClose
} from '@fortawesome/free-solid-svg-icons'
import withRouter from "../../withRouter";
class Entre extends Component {
constructor(props) {
super(props);
this.state = {
email: '',
password: 'aaa',
isChecked: false,
load:false,
btn:false,
rate:false,
};
}

validarEmail(email) {
  // Regex para validar o email
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  // Verifica se o email corresponde ao padrão da regex
  return regex.test(email);
}
verificarString(string) {
  // Verifica se a string está vazia
  if (string.trim().length === 0) {
   
    return false;
  }

  // Verifica se a string contém espaços em branco
  if (string.indexOf(" ") !== -1) {
    
    return false;
  }

  // A string não está vazia e não contém espaços em branco
  return true;
}


componentDidMount(){
 console.log(this.props)
  document.title = "Login - Welessa Eletronic"
}

handleCheckboxChange = (event) => {
  this.setState({ isChecked: event.target.checked });
};

handleLogin = () => {
  
  const id = toast.loading("carregando...")
  if(this.verificarString(this.state.email) == false){
    toast.update(id, { render:"Preencha o campo senha!", type: "error", isLoading: false,autoClose: 3000});
    return false;
  }


this.setState({btn:true})

fetch(`${window.location.origin}/_auth/step/2/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
  s:this.state.email,
  Acess:this.props.params.id
  })
})
.then(response => response.json())
.then(data => {
  this.setState({btn:false})

  if(data.error == "rate-limit"){
    console.log('rate')
    this.setState({rate:true})
    return false;
  }

  if(data.status == "sucsess"){
   window.location = window.location.origin;
  }else{
    toast.update(id, { render: "Senha incorreta!", type: "error", isLoading: false,autoClose: 1500 });

  }
})


}

render() {
  
if(this.state.rate == true){
  return(<div class="ppr-login">
  <div class="maill-success">
<div class="d-table">
<div class="d-table-cell">
<div class="container">
<div class="success-content">
<FontAwesomeIcon icon={faClose} />
<h2>Acesso negado!</h2>
<p>Prezado usuário,
Por motivos de segurança, informamos que seu acesso foi temporariamente suspeno e você não terá acesso aos recursos e funcionalidades da plataforma.</p>
</div>
</div>
</div>
</div>
</div>
  </div>);
}else{


  return (
    <>
    <div class="account-login section">
            <div class="container">
            <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
            <div class="card login-form">
            <div class="card-body">
            <div class="title">
            
            </div>
            </div>
         
            <div class="form-group input-group">
            <label for="reg-fn">Senha:</label>
            <input class="form-control" value={this.state.email} onChange={(e)=>{
              this.setState({email:e.target.value})
            }} placeholder="informe a senha:" type="password" required=""/>
            </div>
            
            <div class="d-flex flex-wrap justify-content-between bottom-content">
            
            <Link class="lost-pass" to="/cliente/recuperar-senha/">Esqueceu a senha?</Link>
            </div>
            <div class="button">
            <button class="btn" onClick={this.handleLogin} disabled={this.state.btn} type="submit">vetificar</button>
            </div>
            <p class="outer-link">Você ainda não tem conta ? <Link to="/cliente/cadastro/" data-link="">faça o seu cadastro aqui</Link>
            </p>
            </div></div>
            
            </div>
            </div>
            </div>
    
    
    
    
            <ToastContainer />
    
    
    </>
    );

          }
}
}
export default withRouter(Entre)
