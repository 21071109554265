import React, { useState } from "react";
import { Link,useNavigate  } from "react-router-dom"
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import 'react-toastify/dist/ReactToastify.css';

const CEP = (props) => {
  const navigateTo = useNavigate();
  const [cep, setCep] = useState('');


  const handleCepChange = (e) => {
    setCep(e.target.value);
  };

  const handleSubmit = () => {
    const regex = /^\d{2}\.\d{3}\-\d{3}$/;
    if (!regex.test(cep)) {
      toast.error('Formato de cep inválido!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }

    const id = toast.loading("busca");
    toast.update(id, { render: "Buscando...", type: "success", isLoading: true });

    fetch(`${props.host}/_next/api/consultar-cep/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        cep: cep
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.estado === "erro") {
          toast.update(id, { render: "Cep inexistente!", type: "error", isLoading: false, autoClose: 5000 });
        } else {
          toast.update(id, { render: "Busca concluída", type: "success", isLoading: false, autoClose: 3000 });
          navigateTo('/endereco/adicionar/'+data.cod)
        }
      });
  };

  return (
    <>
      <div class="account-login section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
              <div class="register-form">
                <div class="title">
                  <h3>Cadastre seu endereço informando o CEP!</h3>
                  <p>Para garantir que possamos entregar seu pedido corretamente, precisamos que você informe o CEP do seu endereço.</p>
                </div>

                <div class="form-group">
                  <InputMask class="form-control" value={cep} onChange={handleCepChange} mask="99.999-999" placeholder="Digite o CEP" />
                </div>
                <div class="button">
                  <button class="btn" onClick={handleSubmit}>Enviar</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default CEP;