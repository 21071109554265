import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import { Modal,Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Fdp from "./Fdp";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    btnClick:0,
    maxPerm:0,
    valueCusr: '',
    tp:'',
    activeVari:null,
    activePgm:null,
    load:true,
    data:[],
    erro:false,
    cErro:'',
    cfrete:false,
    dFrete:[],
    vfrete:'R$0,00',
    showEtc:false,
    activeETC:null,
    estagioETC:0
    }
  
    this.handleChange = this.handleChange.bind(this);
  }
real(valor){
    return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}
  componentDidMount(){
    document.title = "Finalizar compra - WeLessa Eletronic"
    fetch(`${this.props.host}/_next/api/dados-compra/`)
    .then(response => response.json())
    .then(dados => {

  if(dados.estado == "erro"){
    this.setState({load:false,erro:true,cErro:dados.msg})
    
  }else{
    this.setState({data:dados,load:false})
  }

     

    })
  }

  handleChange(event) {
  
    
    this.setState({ valueCusr: event.target.value });
    if (event.target.value.length > 14) {
      this.setState({ maskc: '99.999.999/9999-99',tp:'cnpj' });
    } else {
      
     this.setState({ maskc: '999.999.999-99',tp:'cpf' });
    }
    
  }

 

  trocaStep(data){

    if(data == 2){
      this.setState({btnClick:data,cfrete:false})

      fetch(`${this.props.host}/_next/api/calcular-frete-qqer/`)
      .then(response => response.json())
      .then(dados => {
     this.setState({dFrete:dados,cfrete:true})
      })
    }else{
      this.setState({btnClick:data})
    }




  }



  render() {
  
    if(this.state.load == true){
      return(<>
      <div class="preloader">
         <div class="preloader-inner">
            <div class="preloader-icon">
               <span></span>
               <span></span>
            </div>
         </div>
      </div>
      </>)
    }
  else{
if(this.state.erro == true){
if(this.state.cErro == "auth"){
  return(<>
    <div class="error-area">
    <div class="d-table">
    <div class="ajusta">
    <div class="container">
    <div class="error-content">
    <img class="ErrImg" src={this.props.host+'/_next/static/imagens/login.svg'} />
    <h2>
    Faça login para concluir sua compra e aproveitar todos os benefícios!"
    </h2>
    <p>Para finalizar sua compra e aproveitar todos os benefícios exclusivos, é necessário fazer login em sua conta. </p>
    <div class="button">
    <Link to="/authorization/user/" class="btn">Login</Link>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>)
}else if(this.state.cErro == "sedrc"){
  return(<>
    <div class="error-area">
    <div class="d-table">
    <div class="ajusta">
    <div class="container">
    <div class="error-content">
    <img class="ErrImg" src={this.props.host+'/_next/static/imagens/endereco.svg'} />
    <h2>Cadastre seu endereço para concluir sua compra agora mesmo!
    </h2>
    <p>Não perca mais tempo! Para garantir que sua compra seja entregue corretamente, é essencial que você cadastre seu endereço de entrega.</p>
    <div class="button">
    <Link to="/endereco/cep/" class="btn">Adicionar endereço</Link>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>)
}else if(this.state.cErro == "EsEdrc"){
  return(<>
    <div class="error-area">
    <div class="d-table">
    <div class="ajusta">
    <div class="container">
    <div class="error-content">
    <img class="ErrImg" src={this.props.host+'/_next/static/imagens/endereco.svg'} />
    <h2>Selecione seu endereço de entrega para receber seu produto!
    </h2>
    <p>Para garantir que você receba seu produto com segurança e no local desejado, selecione um de seus endereços de entrega disponíveis. </p>
    <div class="button">
    <Link to="/endereco/" class="btn">Selecionar endereço</Link>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>)
}


}else{
  return(<>
  
  <section class="checkout-wrapper section">
        <div class="container">
        <div class="row justify-content-center">
       
            <div class="col-lg-8">
                <div class="checkout-steps-form-style-1">
              
                
            
               
            <div class="title">
            <div class="multisteps-form__progress">
                {[
                {titulo:"Dados pessoais"},
                {titulo:"Informações de entrega"},
                {titulo:"Método de envío"},
                {titulo:"Forma de pagamento"},
              
                {titulo:"Finalizar compra"},
                ].map((item,index)=>(
                <>
                 <button class={this.state.btnClick >= index ? "multisteps-form__progress-btn js-active" : "multisteps-form__progress-btn"} onClick={()=>{
                   this.trocaStep(index)
                 }} type="button">{item.titulo}</button>
                </>    
                ))}
                  
            </div>
            </div> 
              
              
              <section class="checkout-steps-form-content collapse show" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
               
               
               
               
                <div class="row">
                <div class="col-md-12">
                <div class="single-form form-defaults">
            
             
               
<div class="multisteps-form__forma">
                {this.state.btnClick === 0 && (
        <>




  
                <div class="row">
                <div class="col-md-12">
                <div class="single-form form-default">
            
                <div class="row">
               
                <div class="col-md-6 form-input form">
                <label for="reg-fn">Nome completo:</label>
                <input type="text" value={this.state.data.user.nome} class="ifmP" disabled="true" placeholder="Nome completo:"/>
                </div>
                <div class="col-md-6 form-input form">
                <label for="reg-fn">Sobrenome:</label>
                <input type="text" value={this.state.data.user.sobrenome} disabled="true" class="ifmP" placeholder="E-mail:"/>
                </div>
                </div>
                </div>
                </div>
                <div class="col-md-6">
                <div class="single-form form-default">
            
                <div class="form-input form">
                <label for="reg-fn">Celular:</label>
                <InputMask 
    disabled="true"
    value={this.state.data.user.telefone}
    
    />
                </div>
                </div>
                </div>
                <div class="col-md-6">
                <div class="single-form form-default">
            
                
                </div>
                </div>
                
                
            
                <div class="col-md-12">
                <div class="single-form button">
                <button class="btn ml-auto" onClick={()=>{
                    this.trocaStep(this.state.btnClick + 1)
                }} id="pmps">Próximo passo</button>
                </div>
                 </div>
                </div>
              
        </>
      )}
      {this.state.btnClick === 1 && (
        <>
                {this.state.data.endereco != undefined ? <>
                  <div class="row">
                  <div class="col-md-12">
                  <div class="single-form form-default">
               
                  <div class="row">
                  <div class="alt-dados" id="alt_edrc">
                  <Link to="/endereco?show=true">Alterar endereço</Link>
                  </div>
                  <div class="col-md-6 form-input form">
                  <label for="reg-fn">Cep:</label>
                  <input type="text" value={this.state.data.endereco.cep} disabled="true" class="iptEdrc" placeholder="Cep:" maxlength="10"/>
                  </div>
                  <div class="col-md-6 form-input form">
                  <label for="reg-fn">Endereço:</label>
                  <input type="text" value={this.state.data.endereco.rua} disabled="true" class="iptEdrc" placeholder="Endereço:"/>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div class="single-form form-default">
                  <div class="form-input form">
                  <label for="reg-fn">Numero:</label>
                  <input type="text" value={this.state.data.endereco.numero} disabled="true" class="iptEdrc" placeholder="Numero:"/>
                  </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div class="single-form form-default">
                  <div class="form-input form">
                  <label for="reg-fn">Bairro:</label>
                  <input type="text" value={this.state.data.endereco.bairro} disabled="true" class="iptEdrc" placeholder="Bairro:"/>
                  </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div class="single-form form-default">
                  <div class="form-input form">
                  <label for="reg-fn">Complemento:</label>
                  <input type="text" value={this.state.data.endereco.comp} disabled="true" class="iptEdrc" placeholder="Complemento*:"/>
                  </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div class="single-form form-default">
                  <div class="form-input form">
                  <label for="reg-fn">Cidade:</label>
                  <input type="text" value={this.state.data.endereco.cidade} disabled="true" class="iptEdrc" placeholder="Cidade:"/>
                  </div>
                  </div>
                  </div>
                  <div class="col-md-12">
                  <div class="single-form button">
                  <button class="btn" onClick={()=>{
                  this.trocaStep(this.state.btnClick + 1)
                }}>Próximo passo</button>
                  </div>
                   </div>
                </div>
                </> : <><Link to="/endereco/cep/">Adicionar endereço</Link></>}
               
                
        </>
      )}
      {this.state.btnClick === 2 && (
        <>
       <div class="row" id="mtdEnvio"><div class="col-md-12">

       {this.state.cfrete == false ? <>    <div class="checkout-payment-option">
       
       <div class="payment-option-wrapper">
     

   
       <div class="load" style={{width:200,height:80,marginRight:10}}></div>

       <div class="load" style={{width:200,height:80,marginRight:10}}></div>

       <div class="load" style={{width:200,height:80,marginRight:10}}></div>

    
    
       






       
       </div>
 
       
       </div>
       <div class="col-md-12">
         <div class="single-form button">
         <div class="load" style={{width:160,height:50}}></div>
         </div>
          </div></> : <>
          <div class="payment-option-wrapper">
          {this.state.dFrete.opcs.map((item,index)=>(<>
<div key={index} class={`single-payment-option`}  onClick={(e) => {

if(index == 2){
this.setState({showEtc:true})

}else{
this.setState({activeVari:index})
}
}}>
 <label class={this.state.activeVari === index ? "active" : ""}>
 <p>{item.nome}</p>
 <span class="price">{this.real(item.valor)}</span>
 </label>
</div>
</>))}
         </div> 
         <div class="col-md-12">
                  <div class="single-form button">
                  <button class="btn" onClick={()=>{

if(this.state.activeVari == null){
  toast.error('Selecione um método de envío!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  return false;
}


                  this.trocaStep(this.state.btnClick + 1)
                }}>Próximo passo</button>
                  </div>
                   </div>
          </>}



    
        </div></div>
        </>
      )}
            
            {this.state.btnClick === 3 && (
        <>
       <div class="row" id="mtdEnvio">

        <div class="col-md-12">
        <div class="payment-option-wrapper">

        {[{nome:"Pix"},{nome:"Cartão"},{nome:"Boleto"}].map((item,index)=>(<>
<div key={index} class={`single-payment-option`}  onClick={(e) => {
this.setState({activePgm:index})
}}>
 <label class={this.state.activePgm === index ? "active" : ""}>
 <p>{item.nome}</p>
 </label>
</div>
</>))}

</div>


        </div>
        </div>
        </>
      )}
            
               
                </div>
                
                </div></div></div>
                
                
                
                
                </section>
            
            
            
            
            </div>
            </div>

        <div class="col-lg-4">
        <div class="checkout-sidebar">
        <div class="checkout-sidebar-coupon">
        <p>Digite o seu cupom:</p>
        <form action="#">
        <div class="single-form form-default">
        <div class="form-input form">
        <input type="text" placeholder="Código do cupom:"/>
        </div>
        <div class="button">
        <button class="btn">Aplicar</button>
        </div>
        </div>
        </form>
        </div>
        <div class="checkout-sidebar-price-table mt-30">
        <h5 class="title">Tabela de preço</h5>
        <div class="sub-total-price">
        <div class="total-price">
        <p class="value">Subotal do carrinho:</p>
     
        <p class="price">R$0,00</p>
        </div>
        <div class="total-price shipping" id="somaFrete">
        <p class="value">Frete:</p>
        {this.state.cfrete == false ? <><p class="price">R$0,00</p></> : <><p class="price">{this.state.dFrete.frete}</p></> }
      
        </div>
       
        </div>
        <div class="total-payable">
        <div class="payable-price">
        <p class="value">Total a pagar:</p>
        <p class="price" id="TotalPagar">Selecione o metodo de envio</p>
        </div>
        </div>
       
        </div>
        
        </div>
        </div>
        </div>
        </div>
  </section>
  {this.state.showEtc == true && <>
    <Modal show={this.state.showEtc} onHide={()=>{
        this.setState({showEtc:false})
      }}>
      <Modal.Header closeButton>
        <Modal.Title> {this.state.data.estacao}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="checkout-steps-form-style-1">
      <div class="payment-option-wrapper">
    
        {this.state.dFrete.estacao.map((item,index)=>(<>
<div key={index} class={`single-payment-option`}  onClick={(e) => {
this.setState({activeETC:index})
}}>
 <label class={this.state.activeETC === index ? "active" : ""}>
 <p>{item.nome}</p>
 </label>
</div>
</>))}
</div>
</div>
</Modal.Body>

{this.state.estagioETC == 0 ? <>
      <Modal.Footer>
        <Button variant="secondary" >
          Cancelar
        </Button>
        <Button variant="primary" onClick={()=>{
          this.setState({estagioETC:1})
        }}>
        Salvar e continuar
        </Button>
      </Modal.Footer></> : <>
      <Modal.Footer>
        <Button variant="secondary" >
         Voltar
        </Button>
        <Button variant="primary">
        Salvar
        </Button>
      </Modal.Footer></>}



    </Modal>
  </>}
 
        <ToastContainer />
  </>)
}
  }
  }
}

export default Checkout;