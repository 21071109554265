import React, { Component } from 'react';
import '../ButtonRipple/button.css';

class ButtonRipple extends Component {
    handleClick = (event) => {
      const { onClick } = this.props;
      if (onClick) {
        onClick(event);
      }
  
      const button = event.currentTarget;
      const ripple = document.createElement('span');
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
  
      ripple.style.width = ripple.style.height = `${diameter}px`;
      ripple.style.left = `${event.clientX - button.getBoundingClientRect().left - radius}px`;
      ripple.style.top = `${event.clientY - button.getBoundingClientRect().top - radius}px`;
      ripple.classList.add('ripple');
  
      const existingRipple = button.getElementsByClassName('ripple')[0];
      if (existingRipple) {
        button.removeChild(existingRipple);
      }
  
      button.appendChild(ripple);
    };
  
    render() {
        const { className, children } = this.props;
  
      return (
        <button className={`ripple-button ${className}`} onClick={this.handleClick}>
          {children}
        </button>
      );
    }
  }
  
  export default ButtonRipple;