import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slideshow from '../../../../comps/Slideshow';
class HeroArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Slider:[],
      load:true
    };
  }

  

  componentDidMount() {

    
    fetch(`${this.props.host}/_next/api/inicio?bloco=slider`)
      .then(response => response.json())
      .then(data => {

   

        var Object = [];

        

       data.forEach((item,index)=>{
        Object.push(
            {
                image: `${this.props.host}/_next/static/`+item.imagem,
                title: item.titulo,
                description: item.titulo,
              }
        )
       })



        this.setState({ Slider:Object,load:false });

      });
  }

  render() {
    const divStyle = {

      backgroundImage: `url(/_next/static/imagens/sistema/banner1.png)`, // Define a imagem de fundo
 
    };
  
    const divStyle2 = {

      backgroundImage: `url(/_next/static/imagens/sistema/banner1.png)`, // Define a imagem de fundo
 
    };
  
 


    return (
      <>
        <section className="hero-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-12 custom-padding-right">
                <div className="slider-head">
                {this.state.load == true ? <div className="load" style={{height:500}}></div> :  <Slideshow slides={this.state.Slider} interval={10000} />}
                   

                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-12 md-custom-padding">
                    
                    
                  {this.state.load == true ? <div className="load hero-small-banner"></div> : <div className="hero-small-banner" style={divStyle}></div>}
                    
                  </div>
                  <div className="col-lg-12 col-md-6 col-12 md-custom-padding">
                  {this.state.load == true ? <div className="load hero-small-banner"></div> : <div className="hero-small-banner" style={divStyle2}></div>}
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HeroArea;