import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./estilo.css"
import ButtonRipple from "../../paginas/Produto/ButtonRipple";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faClose,
    faArrowUp
    
 } from '@fortawesome/free-solid-svg-icons'
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
   chatOpen:false
    }
  

  }



 





  render() {

  return(<>
<div class="area-chat">
    {this.state.chatOpen == false ? <><ButtonRipple className="evmsg" onClick={()=>{
    this.setState({chatOpen:true})
}}>Falar com consultor de vendas</ButtonRipple></> : <> 


<div class="chatBox" id="chatBox">
       
       
         <div class="card">
        
        <header class="card-header header-title">
           <p class="card-header-title"><i class="fa fa-circle is-offline"></i>&nbsp;WeLessa</p>
           <div class="card-header-icon" onClick={()=>{
            this.setState({chatOpen:false})
           }}>
             <span class="icon">
             <FontAwesomeIcon icon={faClose} />
             </span>
           </div>
         </header>
        
        <div id="chatbox-area">
       
       
       
         <div class="card-content chat-content">
   
         <div class="content area-msg">
            
     
       
       
         <div class="chat-message-group">
         <div class="chat-thumb">
         
         </div>

         <div class="chat-messages">
           <div class="message">Olá.
           Esta é uma mensagem automática, caso tenha alguma dúvida envie suas perguntas aqui e um atendente entrará em contato por este chat!</div>
           <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
           Hoje 0:4</div>
         </div>
 </div>
            
       <div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">oi

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">oi

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">preciso de ajuda

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">kkk

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">ola

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">preciso de ajuda

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">ola

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">kkkk

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div><div class="chat-message-group writer-user" id="undefined">
              <div class="chat-messages">
                <div class="message">tete

            <div class="check-msg">
           
            <i class=" fa fa-eye" aria-hidden="true"></i>
            </div>
            </div>
                <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
                 Hoje 0:4</div>
              </div>
            </div>
             <div class="chat-message-group" id="XJqwY1x244vpi9pC">
                       <div class="chat-thumb">
                       </div>
                       <div class="chat-messages">
                         <div class="message">como posso te ajudar</div>
                         <div class="from">Hoje 0:4</div>
                       </div> 
               </div>
               <div class="chat-message-group writer-user" id="undefined">
  <div class="chat-messages">
    <div class="message">oi

<div class="check-msg">
<i class="fa fa-eye-slash" aria-hidden="true"></i>
</div>
</div>
    <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
     Hoje 0:4</div>
  </div>
</div>
 <div class="chat-message-group" id="jhuigi" vsl="n">
           <div class="chat-thumb">
           </div>
           <div class="chat-messages">
             <div class="message">Olá, Como posso te ajudar hoje? É só digitar un assunto que vou fazer o possível para te ajudar ;)</div>
             <div class="from">Hoje 0:4</div>
           </div>
   </div>
   <div class="chat-message-group writer-user" id="undefined">
  <div class="chat-messages">
    <div class="message">senha

<div class="check-msg">
<i class="fa fa-eye-slash" aria-hidden="true"></i>
</div>
</div>
    <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
     Hoje 0:4</div>
  </div>
</div>
 <div class="chat-message-group" id="jhuigi" vsl="n">
           <div class="chat-thumb">
           </div>
           <div class="chat-messages">
             <div class="message">Dificuldades com a conta?</div>
             <div class="from">Hoje 0:4</div>
           </div>
   </div>
   <div class="chat-message-group writer-user" id="undefined">
  <div class="chat-messages">
    <div class="message">isso

<div class="check-msg">
<i class="fa fa-eye-slash" aria-hidden="true"></i>
</div>
</div>
    <div class="from"><i class="fa fa-clock-o" aria-hidden="true"></i>
     Hoje 0:5</div>
  </div>
</div>
 <div class="chat-message-group" id="jhuigi" vsl="n">
           <div class="chat-thumb">
           </div>
           <div class="chat-messages">
             <div class="message">tenho certeza</div>
             <div class="from">Hoje 0:5</div>
           </div>
   </div>
   </div>
      
         </div>
 
       
       
       
         <div class="chat-footer" id="chatBox-textbox">
          <button id="EnvMsg">
          <FontAwesomeIcon icon={faArrowUp} />

          </button>
             <textarea id="EVmsg" class="chat-textarea" placeholder="Digite aqui"></textarea>
             <div class="opcs">
             <i class="fa fa-smile-o" aria-hidden="true"></i>
         <i class="fa fa-camera" aria-hidden="true"></i>
         <i class="fa fa-shopping-bag" aria-hidden="true"></i>
         
         
           </div>
          
         </div>
         </div>
       </div>
       </div>


</>}

</div>
  </>)

    
    
  }
}

export default Chat;