import React, { useState, useRef, useEffect } from 'react';
import "./slideshow.css"
export default function Slideshow({ slides, interval = 5000 }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [transition, setTransition] = useState(false);

  const containerRef = useRef(null);
  const touchStartXRef = useRef(null);

  useEffect(() => {
    let timer = setInterval(() => {
      if (!isDragging) {
        setCurrentIndex((currentIndex + 1) % slides.length);
        setTranslateX(0);
        setTransition(true);
      }
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex, interval, isDragging, slides.length]);

  const handleTouchStart = (event) => {
    touchStartXRef.current = event.touches[0].clientX;
    setIsDragging(true);
    setTransition(false);
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      const touchX = event.touches[0].clientX;
      const deltaX = touchX - touchStartXRef.current;
      setTranslateX(deltaX);
    }
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      const threshold = containerRef.current.offsetWidth / 4;
      if (Math.abs(translateX) > threshold) {
        if (translateX > 0) {
          setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
        } else {
          setCurrentIndex((currentIndex + 1) % slides.length);
        }
      }
      setTranslateX(0);
      setIsDragging(false);
      setTransition(true);
    }
  };

  const handlePrev = () => {
    setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
    setTranslateX(0);
    setTransition(true);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % slides.length);
    setTranslateX(0);
    setTransition(true);
  };

  return (
    <div
      className="slideshow-container"
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="slides"
        style={{
          transform: `translateX(${-currentIndex * 100 + translateX / slides.length}%)`,
          transition: transition ? 'transform 0.3s ease-out' : 'none',
        }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={slide.title} />
            
          </div>
        ))}
      </div>
      <button className="prev" onClick={handlePrev}>
        &#10094;
      </button>
      <button className="next" onClick={handleNext}>
        &#10095;
        </button>
  <div className="dots">
    {slides.map((_, index) => (
      <span
        key={index}
        className={`dot ${index === currentIndex ? 'active' : ''}`}
        onClick={() => {
          setCurrentIndex(index);
          setTranslateX(0);
          setTransition(true);
        }}
      ></span>
    ))}
  </div>
</div>

);
}


