import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, Router} from "react-router-dom"
import "./App.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
faLink,
faShare,
} from '@fortawesome/free-solid-svg-icons'
import Page404 from "./paginas/404"
import Inicio from "./paginas/inicio"
import Sobre from "./paginas/sobre"
import Pesquisa from "./paginas/Pesquisa"
import Entre from "./paginas/entre"
import CheckPass from "./paginas/CheckPass"
import Cadastro from "./paginas/Cadastro"
import RecuperarSenha from "./paginas/RecuperarSenha"
import Produto from "./paginas/Produto"
import Carrinho from "./paginas/Carrinho"
import Baixar from "./paginas/Baixar"
import Checkout from "./paginas/Checkout"
import Categoria from "./paginas/Categoria"
import Endereco from "./paginas/Endereco"
import AddEndereco from "./paginas/AddEndereco"
import CEP from "./paginas/CEP"
import PPR from "./paginas/PPR"
import Marca from "./paginas/Marca"
import CtgMarca from "./paginas/CtgMarca"
import Afiliados from "./paginas/Afiliados"

import FnzCp from "./paginas/FnzCp"
import Address from "./paginas/FnzCp/Address"
import {isMobile,isBrowser} from 'react-device-detect';
import { Link,useNavigate,useLocation  } from "react-router-dom"

export default function App(props) {
  const navigateTo = useNavigate();
  const location = useLocation()

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileOpen, setMobileOpen] = useState(false);
  const [clipBoard,setClipBoard] = useState('');
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileOpen(false)
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleContextMenu = (e) => {

    if(e.target.tagName == "IMG"){
      if(e.target.parentNode.tagName == "A"){
        setClipBoard(e.target.parentNode.href)
        setMenuPosition({ x: e.clientX, y: e.clientY });
        setTimeout(() => {
          setMobileOpen(true)
        },100);
        setIsMenuOpen(true);
      }
    
    }else if(e.target.tagName == "A"){
      setClipBoard(e.target.href)
      setMenuPosition({ x: e.clientX, y: e.clientY });
      setTimeout(() => {
        setMobileOpen(true)
      },100);
      setIsMenuOpen(true);
    }
    e.preventDefault();
    
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const menuStyle = {
    display: isMenuOpen ? 'block' : 'none',
    position: 'fixed',
    top: menuPosition.y +5,
    left: menuPosition.x +5,
    width:200,
    backgroundColor: '#1b191a',
    borderRadius:5,
    padding: '5px',
    zIndex: 1000,
  };

  return (
<div onContextMenu={handleContextMenu}>
<Routes>

<Route exact  path="/"  element={<Inicio host={props.host}/>} />
<Route path="/sobre/:id/" element={<Sobre host={props.host}/>} />
<Route path="/busca/:id" element={<Pesquisa host={props.host}/>} />
<Route path="/authorization/user/" host={props.host}  element={<PPR/>} />
<Route path="/authorization/login/step/1/:id" host={props.host}  element={<Entre navigateTo={navigateTo} location={location} />} />
<Route path="/authorization/login/step/2/:id" host={props.host}  element={<CheckPass/>} />

<Route path="/cliente/cadastro/" host={props.host} element={<Cadastro/>} />
<Route path="/cliente/recuperar-senha/" host={props.host} element={<RecuperarSenha/>} />

<Route path="/produto/*" element={<Produto  navigateTo={navigateTo} host={props.host}/>} />
<Route path="/compras/carrinho" element={<Carrinho host={props.host}/>} />
<Route path="/compras/finalizar" element={<Checkout host={props.host}/>} />

<Route path="/checkout" element={<FnzCp navigateTo={navigateTo} host={props.host}/>} />
<Route path="/checkout/address" element={<Address host={props.host} navigateTo={navigateTo} location={location}/>} />

<Route path="/app/baixar/" element={<Baixar host={props.host}/>} />
<Route path="/categoria/:id/" element={<Categoria host={props.host}/>} />
<Route path="/marca/:id/" element={<Marca host={props.host}/>} />
<Route path="/marca/:mc/:name/" element={<CtgMarca host={props.host}/>} />
<Route path="/endereco/" element={<Endereco host={props.host}/>} />
<Route path="/endereco/cep/" element={<CEP host={props.host}/>} />
<Route path="/afiliados/" element={<Afiliados host={props.host}/>} />
<Route path="/endereco/adicionar/*" element={<AddEndereco host={props.host}/>} />
<Route  path='*' element={<Page404  host={props.host}/>} />
</Routes>
{isMenuOpen && (<>
{isMobile == true ? <div>
      <div class="camada-menu-mbl"></div>
      <div ref={menuRef} className={`menu-opc ${isMobileOpen ? 'open' : ''}`}>
      <button>Compartilhar</button>
      <button>Copiar link</button>
      </div>
    
</div> : <>
<div class="ctX" ref={menuRef} style={menuStyle}>
  <ul>
    <li class="item share-item">  <FontAwesomeIcon icon={faShare} /><span>Compartilhar</span>
    <ul class="share-menu">
    <li>WhatsApp</li>
    <li>Facebook</li>
    <li>Instagram</li>
    </ul>
    </li>
    <li class="item" onClick={()=>{
      navigator.clipboard.writeText(clipBoard)
      const id = toast.loading("carregando...")
 
    toast.update(id, { render:"Texto copiado para área de transferência", type: "success", isLoading: false,autoClose: 3000});
   
      setIsMenuOpen(false)
    }}> <FontAwesomeIcon icon={faLink} /> <span>Copiar link</span></li>
  </ul>
</div>
</>}
 
</>

)}
<ToastContainer />
</div>
 
  
  )
}

