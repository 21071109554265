import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./estilo.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { 
    faHome,
    faUser,
    faShoppingCart,
    faSquare
    
 } from '@fortawesome/free-solid-svg-icons'

import MyContext from "../../../../comps/context";
class  Bnavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    load:false,
    texto:''
    }
  

  }




 componentDidMount(){
    var txt  = window.location.pathname.split('/busca/')[1];
    if(txt != undefined){
        this.setState({texto:decodeURIComponent(txt)})
    }
   
 }


 handleChange = (event) => {
    this.setState({ texto: event.target.value }); // Atualiza o estado com o valor do input
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
        if (!this.state.texto || this.state.texto.trim().length === 0) {
            return false;
         }
         this.props.navigateTo('/busca/'+this.state.texto)
    }
  }

  render() {

return(<> 
<MobileView>
<nav class="nav-mbl">
  <Link class="nav-item" to="/">
  <FontAwesomeIcon fontSize={20} color='#282828' icon={faHome} />
  <span>Inicio</span>
  </Link>
  
  <Link class="nav-item" to="/compras/carrinho">
  <FontAwesomeIcon fontSize={20} color='#282828' icon={faSquare} />
  <span>Categorias</span>
  </Link>
  
  <Link class="nav-item" to="/compras/carrinho">
  <FontAwesomeIcon fontSize={20} color='#282828' icon={faShoppingCart} />
  <span>Carrinho</span>
  </Link>
  
  <Link class="nav-item" to="/authorization/user/">
  <FontAwesomeIcon fontSize={20} color='#282828' icon={faUser} />
  <span>Perfil</span>
  </Link>

</nav>
</MobileView>


 </>)

    
    
  }
}
Bnavigation.contextType = MyContext;
export default Bnavigation;