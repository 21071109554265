import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyContext from "../context";
import { 
    faClose,
    
 } from '@fortawesome/free-solid-svg-icons'
 import ButtonRipple from "../../paginas/Produto/ButtonRipple";
class ListaCarrinho extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      itens:[],
      load:true,
      valorCar:0,
      c_item:0,
      carVazio:undefined
    };
  }
real(valor){
    return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
}

removeCarrinho(pdt){


        fetch(`${this.props.host}/_next/api/lista-carrinho/rmv/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id:pdt
          })
        })
        .then(response => response.json())
        .then(data => {
        if(data.estado == "sucesso"){
            this.context.updateVariable(data.itens)
            const updatedItems = this.state.itens.filter(item => item.hash !== pdt);
        
            if(updatedItems.length == 0){
              this.setState({carVazio:true})
              this.context.updateRmvPdt([],1)
              return false;
            }
            this.setState({ itens: updatedItems,valorCar:data.valor,c_item:data.itens });
            this.context.updateRmvPdt(pdt,2)
        }else{
        
        }
      }
      )
      




   
}

  componentDidMount() {
    fetch(`${this.props.host}/_next/api/lista-mini-carrinho/`)
      .then((response) => response.json())
      .then((data) => {
        if(data.estado == "erro"){
         this.setState({carVazio:true,load:false})
        }else{
          this.setState({ data: data,load:false,itens:data.itens,valorCar:data.total,c_item:data.c_item,carVazio:false});
        }
       
      })
      .catch((error) => console.log(error));
  }

  render() {



  if(this.state.load == true){
    return (
      <>
      
      <div class="dropdown-cart-header">
                  <span id="t_ilc">
                      <div class="load cart-img" style={{width:100,height:15}}></div>
                  </span>
                 
              </div>
              <ul class="shopping-list"> 
              
              <li id="item_byPNs">
                     <div class="cart-img-head">
                      <div class="load cart-img"></div>
                  </div>
      
                  <div class="content">
                      <h4>
                      <div class="load cart-img" style={{width:'90%',height:15}}></div>
                      <div class="load cart-img" style={{width:'90%',height:15,marginTop:7}}></div>
                      <div class="load cart-img" style={{width:'90%',height:15,marginTop:7}}></div>
                      
                      </h4>
                      <p class="quantity"><span class="amount"><div class="load cart-img" style={{width:80,height:15,marginTop:5}}></div></span></p>
                  </div>
              </li>
              
              <li id="item_byPNs">
                         <div class="cart-img-head">
                      <div class="load cart-img"></div>
                  </div>
      
                  <div class="content">
                      <h4>
                      <div class="load cart-img" style={{width:'90%',height:15}}></div>
                      <div class="load cart-img" style={{width:'90%',height:15,marginTop:7}}></div>
                      <div class="load cart-img" style={{width:'90%',height:15,marginTop:7}}></div>
                      
                      </h4>
                      <p class="quantity"><span class="amount"><div class="load cart-img" style={{width:80,height:15,marginTop:5}}></div></span></p>
                  </div>
              </li>
              
              </ul>
                  <div class="bottom">
                      <div class="total">
                          <span><div class="load" style={{width:100,height:15}}></div></span>
                          <span class="total-amount"><div class="load" style={{width:100,height:15}}></div></span>
                      </div>
                      <div class="button">
                      <div class="load" style={{width:'100%',height:40}}></div>
                      </div>
                  </div>
      </>
      );
    }else{
if(this.state.carVazio == true){
  return <>Vazio!</>
}else{


     return(<>
       
      <div class="dropdown-cart-header">
            <span id="t_ilc">{this.state.c_item} {this.state.c_item > 1 ? 'Produtos' : 'Produto'}</span>
            <Link to="/compras/carrinho" data-link="ok">Ver tudo</Link>
        </div>
        <ul class="shopping-list"> 
        

{this.state.itens.map((item,index)=>{
    return(<li>
   
    <div class="cart-img-head">
        <Link class="cart-img" to={`/produto/${item.hash}`} data-link="ok"><img src={`${this.props.host}/_next/img/${item.imgs.link}&amp;qualidade=70`} alt="#"/></Link>
    </div>

    <div class="content">
        <h4><Link to={`/produto/${item.hash}`} data-link="">{item.titulo}</Link></h4>
        <p class="quantity">{item.quantidade}x - <span class="amount">{(item.valor)}</span></p>
    </div>
</li>)
})}


        
        
        
        
        </ul>
            <div class="bottom">
                <div class="total">
                    <span>Total:</span>
                    <span class="total-amount">{this.real(new Number(this.state.valorCar))}</span>
                </div>
                <div class="button">
                    <Link to="/compras/finalizar" class="btn animate" data-link="">Finalizar compra</Link>
                </div>
            </div>
        
        
        </>)
     }
    }

   

    
    }
}
ListaCarrinho.contextType = MyContext;
export default ListaCarrinho;