import React, { Component } from "react";
import { useLocation,Link,NavLink } from 'react-router-dom';
import "./Estilo.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
faPlay,
faPause 
} from '@fortawesome/free-solid-svg-icons'
    class VideoPlayer extends Component {
      constructor(props) {
        super(props);
        this.state = {
          isPlaying: false,
          progress: 0,
          currentTime: 0,
          videoURL:''
        };
        this.myRef = React.createRef();
        this.progressBarRef = React.createRef();
      }
    
      componentDidMount() {
     
        fetch(`${window.location.origin}/video/${this.props.src[0].link}`)
      .then(response => response.blob())
      .then(blob => {
     
        const blobURL = URL.createObjectURL(blob);
        this.setState({ videoURL: blobURL });
        const video = this.myRef.current;
        video.addEventListener('timeupdate', this.handleProgress);
      })
      .catch(error => {
        console.error('Erro ao obter o vídeo:', error);
      });
      }
    
      componentWillUnmount() {
        const video = this.myRef.current;
        video.removeEventListener('timeupdate', this.handleProgress);
      }
    
      handleProgress = () => {
        const video = this.myRef.current;
        const progress = (video.currentTime / video.duration) * 100;
        this.setState({ progress });
      }




     
    
      handleProgressBarClick = (e) => {
        const progressBar = this.progressBarRef.current;
        const { left, width } = progressBar.getBoundingClientRect();
        const clickX = e.clientX - left;
        const newProgress = (clickX / width) * 100;
        const video = this.myRef.current;
        const newTime = (newProgress / 100) * video.duration;
        video.currentTime = newTime;
        this.setState({ progress: newProgress });
      }


   
      handleClick = () => {
        const video = this.myRef.current;
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
        this.setState(prevState => ({
          isPlaying: !prevState.isPlaying
        }));

      }


      formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
      }

      render() {     
         
        return(<>
<div class="player">
        <div class="player-overlay" >
          <div class="container-video">
           
            <div class="player-container">
              <div class="video-progress"  onClick={this.handleProgressBarClick}
          ref={this.progressBarRef}>
                <div class="video-progress-filled"  style={{ width: `${this.state.progress}%` }}></div>
              </div>
              <div class="player-controls">
                <div class="player-buttons">

                  <button id="play_video" class="button play" type="button" onClick={this.handleClick}>
             

             {this.state.isPlaying == false ?  <FontAwesomeIcon icon={faPlay} /> :  <FontAwesomeIcon icon={faPause} />}
                  </button>

               <div class="volume-progress">
                    <div class="volume-progress-filled" ></div>
                  </div>
                  <div class="time-container">
                    <p class="current-time">{this.formatTime(this.state.currentTime)}</p>
                    <p class="time-separator">/</p>
                    <p class="duration-video">0:00</p>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
        {this.state.videoURL && <>
          <video ref={this.myRef} controls class="video">
        <source src={this.state.videoURL} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
        </>}
        
      </div>
        </>)

        }
}

export default VideoPlayer;