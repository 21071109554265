import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCoffee,
    faUser,
    faMagnifyingGlass,
    faBars,
    faShoppingCart,
    faHeart,
    faLock,
    faLockOpen,
    faClose
    
 } from '@fortawesome/free-solid-svg-icons'

import MyContext from "../../../comps/context";
class Subppr extends Component {
  constructor(props) {
    super(props);
    this.state = {
    error:false,
    load:true
    }
  

  }




 componentDidMount(){
  function generate_token(length){
    //edit the token allowed characters
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];  
    for (var i=0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
  }
    fetch(`${window.location.origin}/_auth/permission/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      })
      .then(response => response.json())
      .then(data => {
      this.setState({load:false})
      if(data.status == "success"){
        this.setState({error:false})
        this.props.navigateTo('/authorization/login/step/1/'+generate_token(32),{
         state:{
          auth:data.TokenAcess
         }
        })
      }else{
        this.setState({error:true})
      }
      })
   
 }




  render() {
if(this.state.load == true){
    return(<div class="ppr-login">
    <div class="maill-success">
<div class="d-table">
<div class="d-table-cell">
<div class="container">
<div class="success-content">
<FontAwesomeIcon icon={faLock} />
<h2>Autorização em andamento...</h2>


</div>
</div>
</div>
</div>
</div>
    </div>);
}else{

if(this.state.error == true){
    return(<div class="ppr-login">
    <div class="maill-success">
<div class="d-table">
<div class="d-table-cell">
<div class="container">
<div class="success-content">
<FontAwesomeIcon icon={faClose} />
<h2>Acesso negado!</h2>
<p>Prezado usuário,
Por motivos de segurança, informamos que seu acesso foi temporariamente suspeno e você não terá acesso aos recursos e funcionalidades da plataforma.</p>
</div>
</div>
</div>
</div>
</div>
    </div>);
}else{
    return(<div class="ppr-login">
    <div class="maill-success">
<div class="d-table">
<div class="d-table-cell">
<div class="container">
<div class="success-content">
<FontAwesomeIcon icon={faLockOpen} />
<h2>Redirecionamento para o Login...</h2>
<p>Você está prestes a ser redirecionado para a página de login</p>
</div>
</div>
</div>
</div>
</div>
    </div>);
}



}
    

    
    
  }
}
Subppr.contextType = MyContext;
export default Subppr;