import React from "react";
import { Link,useParams } from "react-router-dom"
export default function Sobre() {
    const {id} = useParams();

return (
<>
<h1>Aqui é o sobre {id}</h1>
<Link to="/sobre/mudamos">muda</Link>
</>
);
}
