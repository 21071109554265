import React from 'react';

const MyContext = React.createContext();

export const MyProvider = ({ children }) => {
  const [myVariable, setMyVariable] = React.useState('0');
  const [myPdtRmv, setMyPdt] = React.useState([{}]);
  const [histBusca, setHistBusca] = React.useState([{},{},{}]);

  const updateVariable = (value) => {
    setMyVariable(value);
  }

  const setaHistBusca = (value) => {
    setHistBusca(value);
  }



  const updateRmvPdt = (value,type) => {
    if(type == 1){
    setMyPdt([]);
    }else if(type == 2){
      const novoArray = [...myPdtRmv];
      novoArray.push({id:value});
      setMyPdt(novoArray);
    }else if(type == 3){
      setMyPdt(value);
    }
   
  }

  return (
    <MyContext.Provider value={{ myVariable, updateVariable,myPdtRmv,updateRmvPdt,histBusca,setaHistBusca }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;