import React from "react";
import { Link } from "react-router-dom"

export default function Page404(props) {
return (
<>
<div class="error-area">
<div class="d-table">
<div class="center-pg">
<div class="container">
<div class="error-content">
<img class="ErrImg" src={props.host+'/_next/static/imagens/404.svg'}/>
<h2>Oops! página não encontra!</h2>
<p>A página que você está procurando não existe. Pode ter sido removida ou excluída.</p>
<div class="button">
<Link to="/" class="btn">Página inicial</Link>
</div>
</div>
</div>
</div>
</div>
</div>
</>
);
}
