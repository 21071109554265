

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';

class RecuperarSenha extends Component {
constructor(props) {
super(props);
this.state = {
email: '',
password: '',
};
}

componentDidMount(){
    document.title = "Recuperar senha - Welessa Eletronic"
  }

handleEmailChange = (event) => {
this.setState({ email: event.target.value });
}

handlePasswordChange = (event) => {
this.setState({ password: event.target.value });
}

handleLogin = () => {
console.log(this.state.email);
}

render() {
  return (
    <>
   
   <div class="account-login section">
        <div class="container">
        <div class="row">
        <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
        <div class="register-form">
        <div class="title">
        <h3>Esqueci minha senha</h3>
        <p>Para redefinir sua senha informe o seu e-mail cadastrado em sua conta e lhe enviaremos um link com as instruções.</p>
        </div>
        <form class="row" method="post">
        <div class="form-group">
        <input class="form-control" placeholder="E-mail:" type="text" id="email" required="" autocomplete="off"/>
</div>
        <div class="button">
        <button class="btn" id="envRec" type="submit">Enviar</button>
        </div>
        <p class="outer-link">Você já é cadastrado <Link to="/cliente/login/">Faça login agora</Link>
        </p>
        </form>
        </div>
        </div>
        </div>
        </div>
        </div> 
    
    </>
    );
}
}
export default RecuperarSenha;