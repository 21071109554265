import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class FeaturedCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados:{},
      load:true
    };
  }
  primeiraLetraMaiuscula(str) {
    if (str.length === 0) {
        return str; // Retorna a string vazia se não houver caracteres
    } else {
        return str.charAt(0).toUpperCase() + str.slice(1);
        // Extrai o primeiro caractere, converte-o para maiúsculo e concatena com o restante da string
    }
}
  limitarString(str) {
    if (str.length > 25) {
      str = str.substring(0, 25);
      str = str.substring(0, Math.min(str.length, str.lastIndexOf(" ")))+'...';
    }
    return str;
  }
  componentDidMount() {
    fetch(`${this.props.host}/_next/api/inicio?bloco=ced`)
      .then(response => response.json())
      .then(data => {

        this.setState({ dados:data,load:false });

      });
  }

  render() {
   
 


    return (
      <>
  <section class="featured-categories section">
<div class="container">

<div class="row" id="NovosProdutos">
<div class="card-header"><span>CATEGORIAS EM DESTAQUE</span></div>
{this.state.load == true ? <>
    <div class="col-lg-4 col-md-6 col-12"><div class="load" style={{top:40}}></div></div>
    <div class="col-lg-4 col-md-6 col-12"><div class="load" style={{top:40}}></div></div>
    <div class="col-lg-4 col-md-6 col-12"><div class="load" style={{top:40}}></div></div>
    
</> : this.state.dados.map((item, index) => {
  
 if(index <= 5){
    return <div class="col-lg-4 col-md-6 col-12">
    
     <div class="single-category">
     <h3 class="heading"><Link to={`/categoria/${item.ndi}`}>{this.primeiraLetraMaiuscula(item.titulo)}</Link></h3>
     <ul>
    
 {item.data.map((item,index)=>{
    if(index <= 5){
        return <li>
        <Link to={`/produto/${item.link_real}`}>{this.limitarString(item.titulo)}</Link>
    </li>
    }
   
  })}
    
     </ul>
     <div class="images">
     <Link to={`/produto/${item.link_real}`}>
     <img src={`${this.props.host}/_next/img/${item.imagem}&amp;qualidade=50`} alt="#"></img>
     </Link>
     </div>
     </div>
     
</div>  
 }  
  })}





</div>
</div>

</section>
      </>
    );
  }
}

export default FeaturedCategories;
