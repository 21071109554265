import React, { useState, useRef, useEffect,useContext } from 'react';
import {isMobile} from 'react-device-detect';
import "./style.css"
import { Link,useNavigate  } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListaCarrinho from '../../../../comps/ListaCarrinho';
import MyContext from '../../../../comps/context';
import ItensMenu from './ItensMenu';
import IputPsq from './InputPsq';
import IptMobile from './IptMobile';
import { 
   faCoffee,
   faUser,
   faMagnifyingGlass,
   faBars,
   faShoppingCart,
   faHeart,
   faClock,


   
   
} from '@fortawesome/free-solid-svg-icons'

export default function MenuInicio(props) {
   const navigateTo = useNavigate();
   const [scrollDirection, setScrollDirection] = useState(null);
   const [fixedMenu, setFixedMenu] = useState(false);
   const [texto, setTexto] = useState('');
   const [ctrTexto, setCtrTexto] = useState(false)
   const [psMenu, setpsMenu] = useState(0);
   const [isOpen, setIsOpen] = useState(false);
   const [loadData,setData] = useState(false)
   const [busca,setBusca] = useState(false)
   const [showSuggestions, setShowSuggestions] = useState(false);
   const { myVariable, updateVariable } = useContext(MyContext);
   const { histBusca,setHistBusca } = useContext(MyContext);
   const dropdownRef = useRef(null);
   const dropdownSgd = useRef(null);
 
   const toggleDropdown = () => {
     setIsOpen(!isOpen);
   };
 

  
  

   useEffect(() => {
    
  
    
    
      const handleClickOutside = (event) => {
       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setIsOpen(false);
       }

     };
 
     document.addEventListener('click', handleClickOutside);
 
     return () => {
       document.removeEventListener('click', handleClickOutside);
     };
   }, [dropdownRef]);


 


   const handleInputFocus = () => {

      setShowSuggestions(true);
    };
  
  
    useEffect(() => {

var txt  = window.location.pathname.split('/busca/')[1];
setTexto(txt)





      const Sgd = (event) => {
        if (dropdownSgd.current && !dropdownSgd.current.contains(event.target)) {
         
          if(texto != undefined && texto.length == 0){
            setTexto('')
          }
          setShowSuggestions(false);
        }
 
      };
  
      document.addEventListener('click', Sgd);
  
      return () => {
        document.removeEventListener('click', Sgd);
      };
    }, [showSuggestions]);






  


   function handleInputChange(event) {
     setTexto(event.target.value);
   }

   function handleKeyPress(event) {
      if (event.key === 'Enter') {
         if (!texto || texto.trim().length === 0) {
            return false;
         }
         setShowSuggestions(false)
         if(texto.length == 0){
            setTexto('')
         }
         navigateTo('/busca/'+texto,{
            teste:123
         })
      }
   }
 
   function handleClick() {
      if (!texto || texto.trim().length === 0) {
         return false;
      }
      setShowSuggestions(false)
      navigateTo('/pesquisa?p='+texto)
   }




  



if(isMobile == true){
   return(<>
<IptMobile navigateTo={navigateTo}/>
   </>)
}else{

return (
<>
<header style={{marginTop:psMenu}} className={`header navbar-area`}>
         
        
         
         <div class="header-middle">
            <div class="container">
               <div class="row align-items-center ajusta">
                  <div class="col-lg-3 col-md-3 col-7">
                     <Link class="navbar-brand" to="/">
                     <span>WeLessa</span>
                     </Link>
                  </div>
                  <div class="col-lg-5 col-md-7 d-xs-none">
                     <div class="main-menu-search" ref={dropdownSgd}>
                       
                       <IputPsq navigateTo={navigateTo}/>
                    
                     </div>
                    
                  </div>
                  <div class="col-lg-4 col-md-2 col-5">
                     <div class="middle-right-area">
                        <div class="nav-hotline">
                     
                     <Link to="/authorization/user/">Entre ou Cadastre-se</Link>
                   
                        </div>
                        <div class="navbar-cart">
                           <div class="cart-items">
                              <div class="dropdown">
                                 <button id="abreGosto" class="main-btn">
                                 <FontAwesomeIcon icon={faHeart} />
                                 
                                 <span class="total-items">0</span>
                                 
                                 </button>
                                 <div id="myDropdown2" class="dropdown-content">
                                    <div class="shopping-item" id="lista_gosto">
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="cart-items">



                           <div className="dropdown" ref={dropdownRef}>
                           <button id="abreLista"  onClick={toggleDropdown} class="main-btn">
                                 <FontAwesomeIcon icon={faShoppingCart} />
                                 
                                 <span id="tic" class="total-items">{myVariable}</span>
                                 
                                 </button>
      {isOpen && (
        <div id="myDropdown" class="dropdown-contents">
      <div class="shopping-item" id="lista_carrinho">
         
         <ListaCarrinho host={props.host}/>
         
         
            </div>
     </div>
      )}
    </div>


                  
                           </div>
                        </div>
                     </div>
                     
                     <div class="topNav">

</div>


                  </div>
                  
               </div>
            </div>
         </div>
         <div class="container">
<ItensMenu/>
           
         </div>
      </header>
</>
);
      }
}
