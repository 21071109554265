import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const VideoModal = ({ show, handleClose, videoId }) => {
  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Meu Vídeo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       aaaa
      </Modal.Body>
      <Modal.Footer>
        
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;
