import React, { useState, useEffect } from 'react';
import { render } from "react-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./main.css"
import { BrowserRouter } from "react-router-dom"
import MenuInicio from "./paginas/inicio/elementos/menu"
import Footer from './comps/Footer';
import Loader from './comps/Loader';
import Chat from './comps/Chat';
import MyContext, { MyProvider }  from './comps/context';
import App from "./App"
import Bnavigation from './paginas/inicio/elementos/Bnavigation';
import {isMobile} from 'react-device-detect';
import ContexMenu from './comps/ContexMenu'
render(<div>
  <MyProvider>
  <BrowserRouter>
  
   <MenuInicio  host={window.location.origin}/>

    <div class="sub-app" style={{
      position:'relative',
      top:isMobile ? 60  : 180
    }}><App host={window.location.origin} /></div>

 <Bnavigation/>

  </BrowserRouter>
  </MyProvider>
  

  
  </div>
  ,
  document.querySelector("#root")
)
