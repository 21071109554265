import React, { Component } from "react";
import { useLocation,useNavigate,NavLink,Outlet,Navigate,Link } from 'react-router-dom';
import CardProduct from "../../comps/CardProdct";
import withRouter from "../../withRouter";
class Categoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parametro: "",
      data:[],
      load:true,
      erro:false,
      marcas:[],
      titulo:''
    };
   
  }

  


  componentDidMount() {
  var url = window.location.pathname.split('/categoria/')[1];
    fetch(`${this.props.host}/_next/api/itens-categoria?id=${url}`)
    .then(response => response.json())
    .then(data => {
      if(data.estado == "erro"){
     this.setState({erro:true,load:false})
      }else{


        var ObjectMarca = [];
        var arr = data.data; 
        
        for (var i = arr.length - 1; i >= 0; i--) {
       
        
      
        if(arr[i].marca != 0){
        
          ObjectMarca.push({
              titulo:arr[i].marca.titulo,
              link:arr[i].marca.link
          })
          
          }
        
        
        
        }
        
   
        
        
        function getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        }
        
    
        var dataMrc = (getUniqueListBy(ObjectMarca, 'link'))
        

        
      this.setState({data:data.data,load:false,erro:false,marcas:dataMrc,titulo:data.titulo})

      }

    })


  }

  render() {
 
    if(this.state.load == true){
      return (
          <>
            <section class="product-grids section">
            <div class="container">
            <div class="row">
            <div class="col-lg-3 col-12">
            
            <div class="product-sidebar">
            
           
            
            <div id="listCtg" class="single-widget">
    <h3><div class="load" style={{width:'100%',height:14}}></div></h3>
    <ul class="list" id="listCtg">
        <li><div class="load" style={{width:'100%',height:14}}></div></li>
        <li><div class="load" style={{width:'100%',height:14}}></div></li>
        <li><div class="load" style={{width:'100%',height:14}}></div></li>
        <li><div class="load" style={{width:'100%',height:14}}></div></li>
       </ul>
    </div>
            
           
            
            
            <div class="single-widget range">
            <h3><div class="load" style={{width:'100%',height:14}}></div></h3>
            <div class="load" style={{width:'100%',height:14}}></div>
                 <div class="range-inner">
        
           
            </div>
           
            </div>
            
            
           
            
            
          
            
            </div>
            
            </div>
            <div class="col-lg-9 col-12">
            <div class="product-grids-head">
            <div class="product-grid-topbar">
            <div class="row align-items-center">
            <div class="col-lg-7 col-md-8 col-12">
            <div class="product-sorting">
            
            <h3 class="total-show-product">
                <div class="load" style={{width:250,height:14,top:3}}></div>
            </h3>
            </div>
            </div>
            <div class="col-lg-5 col-md-4 col-12">
            <nav>
           
            </nav>
            </div>
            </div>
            </div>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-grid" role="tabpanel" aria-labelledby="nav-grid-tab">
            <div class="row" id="pdts">
                
    
     {[{},{},{}].map((item,index)=>(
        <div class="col-lg-4 col-md-6 col-12">
         <div class="load single-product"></div>               
       </div>
     ))}
    
    
                
                
                            
                            
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </section>
          </>
        );
  }else{



if(this.state.erro == true){
return(<>
<div class="error-area">
<div class="d-table">
<div class="ajusta">
<div class="container">
<div class="error-content">
<img class="ErrImg" src={this.props.host+'/_next/static/imagens/nada.svg'} />
<h2>Nenhum resultado foi encontrado
</h2>
<p>Tente usar palavras-chave diferentes ou mais genéricas</p>
<div class="button">
<Link to="/" class="btn">Página inicial</Link>
</div>
</div>
</div>
</div>
</div>
</div>
</>)
}else{
return(<>
  <section class="product-grids section">
          <div class="container">
          <div class="row">
          <div class="col-lg-3 col-12">
          
          <div class="product-sidebar">
          
         
          
          {this.state.marcas.length > 0 && <>
  <div id="listCtg" class="single-widget">
    <h3>Marcas</h3>
    <ul class="list" id="listCtg">
    {this.state.marcas.map((item,index)=>(
  <li><Link to={`/marca/${item.titulo}`}>{item.titulo}</Link></li>
    ))}
    </ul>
    </div>
</>}
          
         
          
          
          <div class="single-widget range">
          <h3>Faixa de preço até:</h3>
        <div class="range-inner">
          <label>R$</label>
      
          </div>
  
          </div>
          
          
         
          
          
        
          
          </div>
          
          </div>
          <div class="col-lg-9 col-12">
          <div class="product-grids-head">
  
 
          
  
  
  
          <div class="product-grid-topbar">
   <div class="row align-items-center">
      <div class="col-lg-7 col-md-8 col-12">
         <div class="product-sorting">
            <h3 class="total-show-product">Resultados para categoria: <span>{this.state.titulo}</span></h3>
         </div>
      </div>
   </div>
</div>

  
  
          <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-grid" role="tabpanel" aria-labelledby="nav-grid-tab">
          <div class="row" id="pdts">
              
  
  <CardProduct itens={this.state.data} list={4} host={this.props.host}/>
  
            
           </div>
  
  
  
                          
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </section>
  </>)
}






  }
    
  }
}

export default withRouter(Categoria);